import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CacheProvider } from './context';
import { CacheAvionProvider } from './components/cachescontext/contextAvion';
import { CacheVoitureProvider } from './components/cachescontext/contextVoiture';
import { CacheMotoProvider } from './components/cachescontext/contextMoto';
import { CacheVTCProvider } from './components/cachescontext/contextVTC';
import { CacheTaxiProvider } from './components/cachescontext/contextTaxi';
import { CacheTrainProvider } from './components/cachescontext/contextTrain';
import { CacheTCProvider } from './components/cachescontext/contextTC';
import { CacheAlimentaireProvider } from './components/cachescontext/contextAlimentaire';
import { CacheDomicileProvider } from './components/cachescontext/contextDomicile';
import { CacheDomicileMaisonProvider } from './components/cachescontext/contextDomicileMaison';
import { CacheDechetsProvider } from './components/cachescontext/contextDechets';

// Importez vos composants/pages ici
import Home from './pages/Home';
import Introduction from './pages/Particulier/Form Intro/intro';
import Particulier from './pages/Particulier/Form Avion';
import BilanPart from './pages/Particulier/BilanPart';
import FormVoiture1 from './pages/Particulier/Form Voiture/FormVoiture-1';
import FormVoiture2 from './pages/Particulier/Form Voiture/FormVoiture-2';
import FormAl from './pages/Particulier/Form Alimentaire';
import FormDom from './pages/Particulier/Form Domicile';
import FormTC from './pages/Particulier/Form TC';
import FormTrain from './pages/Particulier/Form Train/Train1';
import FormDechet from './pages/Particulier/Form Dechet/index1';
import FormVoitureMoto from './pages/Particulier/Form Voiture/FormVoitureMoto';
import PageVTC from './pages/Particulier/Form Voiture/FormVTC';
import PageTrain from './pages/Particulier/Form Train/Train2';
import BilanPart2 from './pages/Particulier/BilanPart-2';
import BilanPart3 from './pages/Particulier/BilanPart-3';
import FormTC2 from './pages/Particulier/Form TC/index1';
import FormTC3 from './pages/Particulier/Form TC/index2';
import FormAl2 from './pages/Particulier/Form Alimentaire/index1';
import FormDom1 from './pages/Particulier/Form Domicile/index1';
import FormDechet2 from './pages/Particulier/Form Dechet/index2';
import FormVoiture11 from './pages/Particulier/Form Voiture/FormVoiture-11';
import Energie from './pages/Entreprise/Energie';
import Energie1 from './pages/Entreprise/Energie/index1';
import Deplacement from './pages/Entreprise/Deplacement';
import Deplacement1 from './pages/Entreprise/Deplacement/index1';
import Deplacement2 from './pages/Entreprise/Deplacement/index2';
import Deplacement11 from './pages/Entreprise/Deplacement/index11';
import VoyageCol from './pages/Entreprise/Avion';
import VoyageCol1 from './pages/Entreprise/Avion/index1';
import Fret from './pages/Entreprise/Fret';
import Fret1 from './pages/Entreprise/Fret/index1';
import BilanPart4 from './pages/Particulier/BilanPart-4';
import Immobilisation from './pages/Entreprise/Immobilisation';
import Immobilisation2 from './pages/Entreprise/Immobilisation/index1';
import Numerique from './pages/Entreprise/Numerique';
import Numerique2 from './pages/Entreprise/Numerique/index1';
import Achat from './pages/Entreprise/Achat de biens et services';
import Achat2 from './pages/Entreprise/Achat de biens et services/index1';
import Avion from './pages/Particulier/Form Avion/index1';
import Moto from './pages/Particulier/Form Voiture/Moto';
import Maison from './pages/Particulier/Form Domicile/index2';
import BilanEntreprise from './pages/Entreprise/BilanPart';
import BilanPart5 from './pages/Particulier/BilanPart-5';
import Police from './Font/Police';
import CreeCompte from './pages/Connection/CréationCompte/index';
import CreeCompte2 from './pages/Connection/CréationCompte/index2';
import CreeCompte3 from './pages/Connection/CréationCompte/index3';
import Connection from './pages/Connection/Connexion';
import LieuEvent from './pages/Evenement/Lieu/index1';
import DeplacemenetEquipeEvent from './pages/Evenement/Deplacement/index1';
import DeplacemenetParticipant from './pages/Evenement/Deplacement/index2';
import Hebergement from './pages/Evenement/Hebergement/index1';
import Repas from './pages/Evenement/Repas/index1';
import Logistique from './pages/Evenement/Logistique/index1';
import NumeriqueEvent from './pages/Evenement/Numerique/index1';
import DeplacementEvent from './pages/Evenement/Deplacement';
import HebergementEvent from './pages/Evenement/Hebergement';
import RepasEvent from './pages/Evenement/Repas';
import LogistiqueEvent from './pages/Evenement/Logistique';
import NumeriqueEvent1 from './pages/Evenement/Numerique';
import General from './pages/Evenement/Lieu';
import PagePlacement from './pages/Particulier/Form Placement/Placement1';
import PagePlacement1 from './pages/Particulier/Form Placement/Placement2';

import { UUIDProvider } from './components/UUIDContext';
import { FormProgressProvider } from './components/FormProgressContext';
import { ConsoDictProvider } from './components/BilanInter/consoDictContext';
import { AppelDictProvider } from './components/BilanInter/appelDictContext';
import { IdBCProvider } from './components/IdBCContext';
import { UserAuthProvider } from './components/UserAuthContext';
import Bien1 from './pages/Entreprise/Biens et services/index1';
import Bien from './pages/Entreprise/Biens et services';
import AuthProvider from './components/Authentification/Auth';
import { TokenProvider } from './components/Authentification/contextToken';
import EntrepriseAcceuil from './pages/Entreprise/Entreprise';
import AvionVoyage from './pages/Voyage/Form Avion';
import AvionVoyage2 from './pages/Voyage/Form Avion/index1';
import FormTCVoyage from './pages/Voyage/Form TP';
import FormTC2Voyage from './pages/Voyage/Form TP/index1';
import FormTC3Voyage from './pages/Voyage/Form TP/index2';
import FormTrainVoyage from './pages/Voyage/Form Train/Train1';
import PageTrainVoyage from './pages/Voyage/Form Train/Train2';
import FormVoiture1Voyage from './pages/Voyage/Form Voiture/FormVoiture-1';
import FormVoiture2Voyage from './pages/Voyage/Form Voiture/FormVoiture-2';
import FormVoiture11Voyage from './pages/Voyage/Form Voiture/FormVoiture-11';
import PageVTCVoyage from './pages/Voyage/Form Voiture/FormVTC';
import FormVoitureMotoVoyage from './pages/Voyage/Form Voiture/FormVoitureMoto';
import MotoVoyage from './pages/Voyage/Form Voiture/Moto';
import { WordPressAuthProvider } from './components/Authentification/WordPressAuth';
import { FormDataProvider } from './components/FormDataContext';

// Rendu de l'application
ReactDOM.render(
	<React.StrictMode>
    <TokenProvider>
	<CacheProvider>
	<CacheAvionProvider>
	<CacheVoitureProvider>
	<CacheMotoProvider>
	<CacheVTCProvider>
	<CacheTaxiProvider>
	<CacheTrainProvider>
	<CacheTCProvider>
	<CacheAlimentaireProvider>
	<CacheDomicileProvider>
	<CacheDomicileMaisonProvider>
	<CacheDechetsProvider>
    <AuthProvider>
    <WordPressAuthProvider>
    <UUIDProvider>
    <FormDataProvider>
    <FormProgressProvider>
    <IdBCProvider>
    <ConsoDictProvider>
    <AppelDictProvider>
    <UserAuthProvider>
    <Router>
        <Routes>
        <Route path="/" element={<Home />} />
                    
        
        <Route path="particulier" element={<Particulier />}>            
        {/* Ajoutez d'autres sous-routes ici pour les autres pages sous `particulier` */}
        </Route>
        <Route path='Introduction' element={<Introduction />}/>
        <Route path="evenement" element={<LieuEvent />} />
        <Route path="FormAvion" element={<Avion />} />
        <Route path="Voiture-1" element={<FormVoiture1 />} />
        <Route path="Voiture-11" element={<FormVoiture11 />} />
        <Route path="Voiture-2" element={<FormVoiture2 />} />
        <Route path="Moto" element={<Moto />} />
        <Route path="Voiture-Moto" element={<FormVoitureMoto />} />
        <Route path="VTC" element={<PageVTC />} />
        <Route path="Train" element={<FormTrain />} />
        <Route path="Train-1" element={<PageTrain />} />
        <Route path="TC" element={<FormTC />} />
        <Route path="TC-2" element={<FormTC2 />} />
        <Route path="TC-3" element={<FormTC3 />} />
        <Route path="Dechet" element={<FormDechet />} />
        <Route path="Dechet-2" element={<FormDechet2 />} />
        <Route path="Domicile" element={<FormDom />} />
        <Route path="Domicile-1" element={<FormDom1 />} />
        <Route path="Domicile-2" element={<Maison />} />
        <Route path="Alimentaire" element={<FormAl />} />
        <Route path="Alimentaire-2" element={<FormAl2 />} />
        <Route path="Placement" element={<PagePlacement />} />
        <Route path="Placement-1" element={<PagePlacement1 />} />

        
        <Route path="bilan" element={<BilanPart />} />
        <Route path="bilan-2" element={<BilanPart2 />} />
        <Route path="bilan-3" element={<BilanPart3 />} />
        <Route path="bilan-4" element={<BilanPart4 />} />
        <Route path="bilan-5" element={<BilanPart5 />} />

        <Route path="Entreprise" element={<EntrepriseAcceuil />} />
        <Route path="Entreprise/Energie" element={<Energie />} />
        <Route path="Entreprise/Energie-1" element={<Energie1 />} />
        <Route path="Entreprise/Deplacement" element={<Deplacement />} />
        <Route path="Entreprise/Deplacement-1" element={<Deplacement1 />} />
        <Route path="Entreprise/Deplacement-2" element={<Deplacement2 />} />
        <Route path="Entreprise/Deplacement-11" element={<Deplacement11 />} />
        <Route path="Entreprise/Voyage" element={<VoyageCol />} />
        <Route path="Entreprise/Voyage-1" element={<VoyageCol1 />} />
        <Route path="Entreprise/Fret" element={<Fret />} />
        <Route path="Entreprise/Immobilisation" element={<Immobilisation />} />
        <Route path="Entreprise/Immobilisation-1" element={<Immobilisation2 />} />
        <Route path="Entreprise/Fret-1" element={<Fret1 />} />
        <Route path="Entreprise/Bien" element={<Bien />} />
        <Route path="Entreprise/Bien-1" element={<Bien1 />} />
        <Route path="Entreprise/Numerique" element={<Numerique />} />
        <Route path="Entreprise/Numerique-1" element={<Numerique2 />} />
        <Route path="Entreprise/Achat" element={<Achat />} />
        <Route path="Entreprise/Achat-1" element={<Achat2 />} />
        <Route path="Entreprise/Bilan" element={<BilanEntreprise />} />

        <Route path="Police" element={<Police />} />
        <Route path="CreeCompte" element={<CreeCompte />} />
        <Route path="CreeCompte-2" element={<CreeCompte2 />} />
        <Route path="CreeCompte-3" element={<CreeCompte3/>} />
        <Route path="Connection" element={<Connection />} />
        <Route path="Evenement/General" element={<General />} />
        <Route path="Evenement/Lieu" element={<LieuEvent />} />
        <Route path="Evenement/Deplacement" element={<DeplacementEvent />} />
        <Route path="Evenement/Deplacement-Equipe" element={<DeplacemenetEquipeEvent />} />
        <Route path="Evenement/Deplacement-Participant" element={<DeplacemenetParticipant />} />
        <Route path="Evenement/Hebergement" element={<HebergementEvent />} />
        <Route path="Evenement/Hebergement-1" element={<Hebergement />} />
        <Route path="Evenement/Restauration" element={<RepasEvent />} />
        <Route path="Evenement/Restauration-1" element={<Repas />} />
        <Route path="Evenement/Logistique" element={<LogistiqueEvent />} />
        <Route path="Evenement/Logistique-1" element={<Logistique />} />
        <Route path="Evenement/Numerique-1" element={<NumeriqueEvent />} />
        <Route path="Evenement/Numerique" element={<NumeriqueEvent1 />} />

        <Route path="Voyage/Avion" element={<AvionVoyage />} />
        <Route path="Voyage/Avion-1" element={<AvionVoyage2 />} />
        <Route path="Voyage/Voiture-1" element={<FormVoiture1Voyage />} />
        <Route path="Voyage/Voiture-11" element={<FormVoiture11Voyage />} />
        <Route path="Voyage/Voiture-2" element={<FormVoiture2Voyage />} />
        <Route path="Voyage/Moto" element={<MotoVoyage />} />
        <Route path="Voyage/Voiture-Moto" element={<FormVoitureMotoVoyage />} />
        <Route path="Voyage/VTC" element={<PageVTCVoyage />} />
        <Route path="Voyage/Train" element={<FormTrainVoyage />} />
        <Route path="Voyage/Train-1" element={<PageTrainVoyage />} />
        <Route path="Voyage/TC" element={<FormTCVoyage />} />
        <Route path="Voyage/TC-2" element={<FormTC2Voyage />} />
        <Route path="Voyage/TC-3" element={<FormTC3Voyage />} />


        {/* Ajoutez d'autres routes ici pour les autres pages */}
        </Routes>
    </Router>
    </UserAuthProvider>
    </AppelDictProvider>
    </ConsoDictProvider>
    </IdBCProvider>
    </FormProgressProvider>
    </FormDataProvider>
    </UUIDProvider>
    </WordPressAuthProvider>
    </AuthProvider>
	</CacheDechetsProvider>
	</CacheDomicileMaisonProvider>
	</CacheDomicileProvider>
	</CacheAlimentaireProvider>
	</CacheTCProvider>
	</CacheTrainProvider>
	</CacheTaxiProvider>
	</CacheVTCProvider>
	</CacheMotoProvider>
	</CacheVoitureProvider>
	</CacheAvionProvider>
	</CacheProvider>
    </TokenProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
