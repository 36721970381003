import React, { useState, useEffect, useCallback } from 'react';
import './Ajout.css';

const Ajout = ({ values, setValues }) => {
  const [frequence, setFrequence] = useState(values.frequence || '1');
  const [unit, setUnit] = useState(values.unit || 'an');

  const handleChangeFrequence = (event) => {
    const newValue = event.target.value.replace(/\D/g, ''); // Supprimer les caractères non numériques
    setFrequence(newValue);
  };

  const handleClickMinus = (event) => {
    event.preventDefault();
    const newValue = parseInt(frequence) - 1;
    if (!isNaN(newValue)) {
      setFrequence(String(newValue));
    }
  };

  const handleClickPlus = (event) => {
    event.preventDefault();
    const newValue = parseInt(frequence) + 1;
    if (!isNaN(newValue)) {
      setFrequence(String(newValue));
    }
  };

  const handleChangeUnit = (event) => {
    setUnit(event.target.value);
  };

  // Memoize setValues callback to avoid recreating it on every render
  const stableSetValues = useCallback(
    (newValues) => {
      setValues(newValues);
    },
    [setValues]
  );

   useEffect(() => {
    // console.log('avant');
    stableSetValues({ frequence, unit });
    // console.log('après');
  }, [frequence, unit]);

  return (
    <div className="row ajout-container">
      <span className="ajout-label row col-lg-4 col-12">Fréquence de ce voyage :</span>
      <div className="col-lg-6 col-12 row">
        <div className="col-6 ajout-input-container">
          <button className="ajout-button" onClick={handleClickMinus}>-</button>
          <input
            type='text'
            className="ajout-input"
            value={frequence}
            onChange={handleChangeFrequence}
            pattern="[0-9]*"
          />
          <button className="ajout-button" onClick={handleClickPlus}>+</button>
        </div>
        <select className="ajout-select col-6 text-center" value={unit} onChange={handleChangeUnit}>
          <option value="an">par an</option>
          <option value="mois">par mois</option>
          <option value="semaine">par semaine</option>
        </select>
      </div>
    </div>
  );
};

export default Ajout;