import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "../../../components/Header/index";
import Card from "../../../components/Card/index";
import Footer from '../../../components/Footer/Index';
import ContenueLogo from "../../../components/ContenuCard/ContenueLogo";
import ContenueBouton from "../../../components/ContenuCard/ContenueBouton";
import TitreH2 from '../../../components/TitreH2';
import TitreBG from '../../../components/TitreBG';
import ImageVoiture from "../../../components/img/LogoVoiture.png";
import StepperLine from '../../../components/StepperLine/index-test';
import voiture from "../../../components/img/voiture.png";
import moto from "../../../components/img/moto.png";
import It from "../../../components/img/It.png";
import non from "../../../components/img/imageLogoNon.png";
import CacheContextVoiture from '../../../components/cachescontext/contextVoiture';
import CacheContextMoto from '../../../components/cachescontext/contextMoto';
import CacheContextTaxi from '../../../components/cachescontext/contextTaxi';
import CacheContextVTC from '../../../components/cachescontext/contextVTC';
import { cache } from 'react';


const FormVoiture1 = () => {
    const navigate = useNavigate();

    const { cache: cacheVoiture, setCache: setCacheVoiture  } = useContext(CacheContextVoiture);
    const { cache: cacheMoto, setCache: setCacheMoto  } = useContext(CacheContextMoto);
    const { cache: cacheTaxi, setCache: setCacheTaxi  } = useContext(CacheContextTaxi);
    const { cache: cacheVTC, setCache: setCacheVTC  } = useContext(CacheContextVTC);

    const [cardContenu, setCardContenu] = useState(<ContenueLogo Logo={ImageVoiture} Titre="Voiture & 2 roues"/>);


    const handleCardClick = () => {
    setCardContenu(<ContenueBouton petiteCards={petiteCards} setPetiteCards={setPetiteCards} TitreP="Voyage en voiture/2 roues" SousTitreP="Quel type de véhicule motorisé utilisez-vous principalement ?" lien="/Train" formType="voiture"/>);
    };

    // Skip page to ask to fill form or not if already 
    useEffect(() => {
        const isVoiture = Object.keys(cacheVoiture).length != 0
        const isMoto = Object.keys(cacheMoto).length != 0
        const isTaxi = Object.keys(cacheTaxi).length != 0
        const isVTC = Object.keys(cacheVTC).length != 0
        
        if(isVoiture){
            navigate('/Voiture-2')
        }
        else if(isMoto){
            navigate('/Moto')
        }
        else if(isVTC || isTaxi){
            navigate('/VTC')
        }
        
    });


    const [petiteCards, setPetiteCards] = useState([
    { label: "J'ai une voiture", imageName: voiture, lien:'/Voiture-2' },
    { label: "J'ai une moto", imageName: moto, lien:'/Moto'    },
    { label: "Ponctuellement Taxi, VTC, location", imageName: It, lien:'/VTC' },
    { label: "Je n'en utilise pas", imageName: non, lien:'/Train' }
    ]);

    const ajouterPetiteCard = (label, imageName) => {
    const nouvellePetiteCard = { label, imageName };
    setPetiteCards([...petiteCards, nouvellePetiteCard]);
    }

    return (
    <>     
    <div className='page'>     
        <div className="Header">
        <Header />
        </div>
        
        <div className='container-bas container'>
        <StepperLine/>

        <div className='TitreBG'>
        <TitreBG Titre="Calculez vos émissions annuelles" className="titre"/>
        </div>
        <div className="row">
            <div className="col-10 offset-1">
                <TitreH2 Titre="Calculez vos émissions annuelles" className="titre" couleur="#000000"/>
            </div>
            </div>
            <div className="row justify-content-center">
            <div className="col-10">
                <Card contenu={cardContenu} onClick={handleCardClick} color="#024C4A"/>
            </div>
            </div>
            {/* <div className="row justify-content-center">
            <div className="col-8">
                <Footer contenue="Basé sur la dernière version de la Base Empreinte® de l’ADEME." />
            </div>
            </div> */}
        </div>
        </div>
    </>
    );
};

export default FormVoiture1;

