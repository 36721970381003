import React, { createContext, useEffect, useState } from 'react';

const CacheContextAvion = createContext();

export const CacheAvionProvider = ({ children }) => {
	const [cache, setCache] = useState(JSON.parse(sessionStorage.getItem("cacheAvion")) || {});

	const setCacheCustom = () => {
		sessionStorage.setItem("cacheAvion", JSON.stringify(cache))
	}

	return (
		<CacheContextAvion.Provider value={{ cache, setCache, setCacheCustom }}>
			{children}
		</CacheContextAvion.Provider>
	);
};

export default CacheContextAvion;
