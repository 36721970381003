import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../Authentification/Auth';
import { IdBCContext } from '../IdBCContext';
import API_BASE_URL from './config';

const AppelAPITC = ({index, voyage, kmConnu, km, transport, unit, setLancer, Lancer, save, setApiResponse, setConsoVoyages, setAppelTC,  filledData, usedUrl, lancerSave, setLancerSave}) => {
  const navigate = useNavigate();
  const { token, fetchToken } = useAuth();
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { idBC } = useContext(IdBCContext);

  
  useEffect(() => {
    // Vérifier si le token est disponible avant de faire la requête
    if (token && Lancer) { 
       fetchData();
       
     }
  }, [token, Lancer]); // Se ré-exécute lorsque `token` change

  useEffect(() => {
    // Vérifier si le token est disponible avant de faire la requête
    if(token && lancerSave){
       fetchDataToSave(filledData, usedUrl);
       setLancerSave(false)
     }
  }, [token, lancerSave]); // Se ré-exécute lorsque `token` change


  const fetchDataToSave = async (filledData, usedUrl) => {
    setLoading(true);
    filledData.save = true
    
    const options = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    
    try {
      const result = await axios.post(usedUrl, filledData, options);

    } catch (error) {
      console.error("Erreur lors de l'envoi de la requête :", error);
      setResponse('Erreur lors de la récupération des données.');

    } finally {
      setLoading(false);
      setLancerSave(false)    
    }
  }

   const fetchData = async () => {
     setLoading(true);
    const apiUrlConnu = `${API_BASE_URL}/aps_PAutreTransport_Global`;
    const apiUrlInconnu= `${API_BASE_URL}/aps_PAutreTransport_Detail`;
    
    let apiUrl = ''; 
    kmConnu = (kmConnu === "oui")

    // Déterminer l'URL de l'API en fonction de la valeur de `kmConnu`
    if (kmConnu) {
        apiUrl = apiUrlConnu;
      } else {
        apiUrl = apiUrlInconnu;
      }

    const data = kmConnu
      ? {
          IdBC: idBC,
          IdPage: "PAutreTransport",
          kmConnu: kmConnu,
          km: parseFloat(km),
          TypeTransport: transport,
          frequence: 1, // A enlevez car pas dans le formulaire
          unit: unit, // bug a fixer car on ne récupère rien pour l'instant 
          save: save
    }:
    {
        IdBC: idBC,
        IdPage: "PAutreTransport",
        typeTransport: voyage.typeTransport,
        typeVoyage: voyage.typeVoyage,
        depart: voyage.depart.name,
        latitudeDepart: voyage.depart.latitude,
        longitudeDepart: voyage.arrive.longitude,
        arrivee: voyage.arrive.name,
        latitudeArrivee: voyage.arrive.latitude,
        longitudeArrivee: voyage.arrive.longitude,
        frequence: voyage.ajout.frequence,
        unit: voyage.ajout.unit,
        save: save
      };
    const options = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    
    //Faire le if sur la condition km connu =true
    try {
      const resultTC = await axios.post(apiUrl, data, options);
      // Stocker à jour l'appel à l'API pour plus tard
      setAppelTC(index, data, apiUrl)
       // Mettre à jour la réponse de l'API dans le composant parent
       setApiResponse(resultTC.data)
       if (!kmConnu) {
        setConsoVoyages(index, resultTC.data.ConsoVoyageTC)
       }
       setLancer(false);

    } catch (error) {
      console.error("Erreur lors de l'envoi de la requête :", error);
      setResponse('Erreur lors de la récupération des données.');
    } finally {
      setLoading(false);
    }
  };
  return (null);
};

export default AppelAPITC;
