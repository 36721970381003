import React, { createContext, useState } from 'react';

const CacheContextTrain = createContext();

export const CacheTrainProvider = ({ children }) => {
		const [cache, setCache] = useState(JSON.parse(sessionStorage.getItem("cacheTrain")) || {});

	const setCacheCustom = () => {
		sessionStorage.setItem("cacheTrain", JSON.stringify(cache))
	}

	return (
		<CacheContextTrain.Provider value={{ cache, setCache, setCacheCustom }}>
			{children}
		</CacheContextTrain.Provider>
	);
};

export default CacheContextTrain;
