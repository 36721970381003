import React, { createContext, useState } from 'react';

const CacheContextMoto = createContext();

export const CacheMotoProvider = ({ children }) => {
		const [cache, setCache] = useState(JSON.parse(sessionStorage.getItem("cacheMoto")) || {});

	const setCacheCustom = () => {
		sessionStorage.setItem("cacheMoto", JSON.stringify(cache))
	}

	return (
		<CacheContextMoto.Provider value={{ cache, setCache, setCacheCustom }}>
			{children}
		</CacheContextMoto.Provider>
	);
};

export default CacheContextMoto;
