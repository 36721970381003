import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from '../Input';
import Bouton from '../Bouton';
import ListDeroule from '../ListDeroule';
import 'bootstrap/dist/css/bootstrap.min.css';
import SousTitre from '../../SousTitre';
import './form.css';
import CheckboxChoixUnique from '../checkBox/checkbox';
import Compteur from '../../Compteur/Compteur';
import CheckboxChoixUniqueAdapt from '../checkBox/checkbox-adapt';
import imageEnergie from "../../img/Energie.png"
import ConsomationAnnel from './DomicileAnnuel';
import ConsomationAnnuelCollectif from './DomicileAnnuelCollectif';
import BilanInter from '../../BilanInter';
import { useFormProgress } from '../../FormProgressContext';
import AppelAPIDomicile from '../../Api/AppelAPIDomicile';
import CacheContextDomicileMaison from '../../../components/cachescontext/contextDomicileMaison';
import {useAppelDict} from '../../BilanInter/appelDictContext'

function FormDomicileMaison({ onSubmit }) {
  const { cache, setCache, setCacheCustom } = useContext(CacheContextDomicileMaison);

  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { formStatus, setFormStatus } = useFormProgress();
  const [displayBilan, setDisplayBilan] = useState(false);
  const [lancer, setLancer] = useState(false);
  const [consommationConnue, setConsommationConnue] = useState("");
  const [ChauffageColl, setChauffageColl] = useState(() => cache.ChauffageColl || "");
  const [Renove, setRenove] = useState(() => cache.Renove || "");
  const [modeChauffage, setModeChauffage] = useState(() => cache.modeChauffage || "");
  const [Date, setDate] = useState("");
  const [afficherCheckboxAdapt, setAfficherCheckboxAdapt] = useState(false);
  const [apiResponse, setApiResponse] = useState("");
  const reponses = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
  const reponseA = ['Avant 1975', 'Après 1975'];
  const reponseChauffCol = ['Gaz', 'GPL', 'Chauffage urbain', 'Bois et plaquettes', 'Fioul'];

  const [domicileData, setDomicileData] = useState(() => cache.domicileData || {
    TypeDeLogement: 279640000,
    NbPersonnes: 1,
    Surface: 20,
    ConsommationConnue: null,
    EtiquetteLettre: null,
    Etiquette: null,
    DateConstructionDate: null,
    DateConstruction: null,
    Renovation: null,
    Pays: 279640000,
    ElectriciqueEuro: null,
    ElectriciqueUnit: null,
    ChauffageUrbainEuro: null,
    ChauffageUrbainUnit: null,
    ChauffageCollectif: null,
    GplEuro: null,
    GplUnit: null,
    GazEuro: null,
    GazUnit: null,
    BoisEuro: null,
    BoisUnit: null,
    FioulEuro: null,
    FioulUnit: null,
    PanneauSol: null,
    PanneauPhoto: null,
    Eolienne: null,
    ChauffageElectrique: false,
    ChauffageFioulCollectif: false,
    ChauffageFioulIndividuel: false,
    ChauffageGazCollectif: false,
    ChauffageGazIndividuel: false,
    ChauffageGPL: false,
    ChauffageReseau: false,
  })

  // Update cache when voyages change
  useEffect(() => {
  setCache(prevCache => ({
    ...prevCache,
    domicileData,
    ChauffageColl,
    modeChauffage,
    Renove
  }));
  setCacheCustom()
  }, [
    domicileData,
    ChauffageColl,
    modeChauffage,
    Renove
  ]);

  const { appelDict, setAppelDict } = useAppelDict();

  // Méthode pour stocker les appels fait à voiture
  const updateAppelDomicileMaison = (index, appelDomicileMaison, apiUrl) => {
		setAppelDomicileMaison((prevAppelDomicileMaison) => {
            const newAppelDomicileMaison = [...prevAppelDomicileMaison];
			      newAppelDomicileMaison[index] = { ...newAppelDomicileMaison[index], appelDomicileMaison: appelDomicileMaison, apiUrl: apiUrl };
            addToAppelDict(newAppelDomicileMaison)
			      return newAppelDomicileMaison;
		});
  };

  // Etat pour stocker les voyages
  const [appelVoyages, setAppelDomicileMaison] = useState(() => [
    {
  },
  ]);

  // Méthode pour stocker tous les appels API 
  const addToAppelDict = (appelsDomicileMaison) => {
    setAppelDict(prev => {
        const newAppelDict = { ...prev, 'domicileMaison': appelsDomicileMaison };
        console.log("Appel Dict", newAppelDict);
        return newAppelDict;
    });
  };

  useEffect(() => {
    setFormStatus({
        ...formStatus,
        domicile: 'en cours',
    });
   }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    setDisplayBilan(true);
    console.log(formSubmitted);
  }

  const handleButtonClickAPI = () => {
    setLancer(true);
    setDisplayBilan(true);
    setFormSubmitted(false);
    // Vous pouvez effectuer ici des traitemensts supplémentaires avec les arguments si nécessaire

  };

  const handleButtonClick = (event) => {
    event.preventDefault();
    navigate('/Dechet'); // Ajout de la navigation après la soumission du formulaire
  };

  const handleCompteurNbPersonnes = (value) => {
    setDomicileData((prevDomicile) => {
      const updatedDomicile = {...prevDomicile};
      updatedDomicile.NbPersonnes = value;
      return updatedDomicile;
    })
  }

  const handleCompteurSurface = (value) => {
    setDomicileData((prevDomicile) => {
      const updatedDomicile = {...prevDomicile};
      updatedDomicile.Surface = value;
      return updatedDomicile;
    })
  }

  const handleChangeConsommationConnue = (value) => {
    setDomicileData((prevDomicile) => {
      const updatedDomicile = {...prevDomicile};
      updatedDomicile.ConsommationConnue = value;
      if(value == false){
        updatedDomicile.Etiquette = null;
        updatedDomicile.EtiquetteLettre = null;
      }
      return updatedDomicile;
    })
  }

  const handleCheckboxEtiquette = (value) => {
    setDomicileData((prevDomicile) => {
      const updatedDomicile = {...prevDomicile};

      domicileData.EtiquetteLettre = value
      let etiquetteNum;
      switch (value) {
        case 'A':
          etiquetteNum = 279640000;
          break;

        case 'B':
          etiquetteNum = 279640001;
          break;
            
        case 'C':
          etiquetteNum = 279640002;
          break;
          
        case 'D':
          etiquetteNum = 279640003;
          break;
          
        case 'E':
          etiquetteNum = 279640004;
          break;
          
        case 'F':
          etiquetteNum = 279640005;
          break;
          
        case 'G':
          etiquetteNum = 279640006;
          break;
        default:
          etiquetteNum = value;
      }
      updatedDomicile.Etiquette = etiquetteNum;

      return updatedDomicile;
    })
  };

  const handleCheckboxDate = (value) => {
    setDomicileData((prevDomicile) => {
      const updatedDomicile = {...prevDomicile};
      let dateConstruction;

      domicileData.DateConstructionDate = value
      switch (value) {
        case 'Avant 1975':
          dateConstruction = 279640000;
          break;
        
        case 'Après 1975':
          dateConstruction = 279640002;
          break;
        default:
          dateConstruction = value;
      }

      updatedDomicile.DateConstruction = dateConstruction;
      return updatedDomicile;
    })
  }

  const handleRenovation = (value) => {
    setDomicileData((prevDomicile) => {
      const updatedDomicile = {...prevDomicile};
      updatedDomicile.Renovation = value;
      return updatedDomicile;
    })
  }

  const handleChauffColl = (value) => {
    setDomicileData((prevDomicile) => {
      const updatedDomicile = {...prevDomicile};
      updatedDomicile.ChauffageCollectif = value;
      return updatedDomicile;
    })
  }

  const handleChangePays = (value) => {
    setDomicileData((prevDomicile) => {
      const updatedDomicile = { ...prevDomicile };
      updatedDomicile.Pays = value;
      return updatedDomicile
    })
  }

  const handleModeChauffage = (value) => {
    setModeChauffage(value);
  }

  const handleCheckboxChange = (value) => {
    setConsommationConnue(value);
    setAfficherCheckboxAdapt(value === "oui");
  };

  const handleCheckboxChangeRenove = (value) => {
    setRenove(value);
    setAfficherCheckboxAdapt(value === "oui");
  };

  const handleListDerouleChange = (value) => {
    console.log('ListDeroule value:', value);
    // Faites ce que vous souhaitez avec la valeur sélectionnée
  };

  useEffect(() => {
    if (displayBilan) {
      setFormStatus({
        ...formStatus,
        domicile: 'fini',
      });
    } else if (formSubmitted) {
      setFormStatus({
        ...formStatus,
        domicile: 'en cours',
      });
    }
  }, [formSubmitted]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 col-lg-7">
          <Compteur titre="Nombre total de personnes vivant dans le logement" val={domicileData.NbPersonnes} onChange={handleCompteurNbPersonnes}/>
        </div>
        <div className="col-12 col-lg-5">
          <Compteur titre="Surface du logement" unit="m²" val={domicileData.Surface} onChange={handleCompteurSurface} />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <CheckboxChoixUnique
            onChange={(value) => handleChangeConsommationConnue(value)}
            titre="Connaissez-vous la catégorie de votre logement dans l’étiquette climat (kgeqCO₂/m².an) ?"
            rep1="Oui"
            rep2="Non, mais aidez-moi à la calculer"
            value={domicileData.ConsommationConnue == null ? null : domicileData.ConsommationConnue ? "oui" : "non" || null}
          />
        </div>
      </div>

      {domicileData.ConsommationConnue === null ? null : domicileData.ConsommationConnue ? (
        <div className="row Connais">
          <div className="col-12 col-lg-4">
            <CheckboxChoixUniqueAdapt
              titre="Quelle est votre étiquette climat ?"
              reponses={reponses}
              onChange={handleCheckboxEtiquette}
              value={domicileData.EtiquetteLettre}
            />
          </div>
          <div className="col-12 col-lg-6">
            <img src={imageEnergie} />
          </div>
          <div className="col-12 col-lg-2" style={{ height: "150px", paddingLeft: "50px" }}>
            <div className='p3' style={{ backgroundColor: "#EBE085", padding: "10%" }}>
              L'étiquette climat pour connaître la quantité de gaz à effet de serre émise (à droite sur l'image) n'est pas à confondre avec l'étiquette énergie à gauche.
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="row">
            <div className="col-12 col-lg-12">
              <CheckboxChoixUniqueAdapt
                titre="Date de construction"
                reponses={reponseA}
                onChange={(value) => handleCheckboxDate(value)}
                value={domicileData.DateConstructionDate}
              />
            </div> 
          </div>

          {domicileData.DateConstruction && (
            <div className="row">
              <div className="col-12">
                <CheckboxChoixUnique
                  onChange={(value) => handleRenovation(value)}
                  titre="Avez-vous réalisé une rénovation énergétique (isolation, changement de mode de chauffage,…) dans les 10 dernières années ?"
                  rep1="Oui"
                  rep2="Non"
                  value={domicileData.Renovation == null ? null : domicileData.Renovation ? "oui" : "non"}
                />
              </div>
            </div>
          )}

          {domicileData.Renovation === null ? null : (
            <div>
              <div className="row">
                <div className="col-12 col-lg-4">
                  <ListDeroule
                    titre="Pays"
                    value={domicileData.Pays}
                    mots={["France","Espagne", "Italie", "Portugal"]}
                    onChange={(value) => {
                      let convertedValue;
                      switch (value) {
                        case "France":
                          convertedValue = 279640000;
                          break;
                        case "Espagne":
                          convertedValue = 279640001;
                          break;
                        case "Italie":
                          convertedValue = 279640002;
                          break;
                        case "Portugal":
                          convertedValue = 279640003;
                          break;
                        default:
                          convertedValue = value; 
                        }
                      handleChangePays(convertedValue);}}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <CheckboxChoixUnique
                    onChange={(value) => handleChauffColl(value)}
                    titre="Réseau de chauffage"
                    rep1="Collectif"
                    rep2="Individuel"
                    value={domicileData.ChauffageCollectif === null ? null : domicileData.ChauffageCollectif ? "oui" : "non"}
                  />
                </div>
              </div>
            </div>
          )}

          {domicileData.ChauffageCollectif === null ? ("") : domicileData.ChauffageCollectif ? (
            <div className="row">
              <div className="col-12">
                <CheckboxChoixUniqueAdapt
                  titre="Mode de chauffage collectif"
                  reponses={reponseChauffCol}
                  onChange={handleModeChauffage}
                  value={modeChauffage}
                />
                <ConsomationAnnuelCollectif setDomicileData={setDomicileData} domicileData={domicileData}/>
              </div>
            </div>
          ) : (
            <ConsomationAnnel setDomicileData={setDomicileData} domicileData={domicileData}/>
          )}
        </div>
      )}

      <div className="row">
        <div className="col-12">
          <Bouton titre="CALCULER" onClick={handleButtonClickAPI} />
          <AppelAPIDomicile domicileData={domicileData} setLancer={setLancer} Lancer={lancer} save={false} setApiResponse={setApiResponse} setAppelDomicile={updateAppelDomicileMaison} />
        </div>
      </div>

      {formSubmitted && (
        <div>
          <div className="row">
            <div className="col-12">
              <BilanInter voyage="non" numeroEtape={6} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Bouton titre="Catégorie suivante" onClick={handleButtonClick} />
            </div>
          </div>
        </div>
      )}
    </form>
  );
}

export default FormDomicileMaison;

