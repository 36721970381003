import React, { createContext, useContext, useState } from 'react';

const ContextToken = createContext();

export const TokenProvider = ({ children }) => {
	const [tokenContext, setTokenContext] = useState(null);
	const [tokenContextExpiry, setTokenContextExpiry] = useState(null);

	const loginToken = (newToken) => {
		setTokenContext(newToken); // Sauvegarde du token
	  };


	const logoutToken = () => {
		setTokenContext(null); // Supprime le token
	};

	return (
		<ContextToken.Provider value={{ tokenContext, loginToken, logoutToken, tokenContextExpiry, setTokenContextExpiry }}>
			{children}
		</ContextToken.Provider>
	);
};

export const useToken = () => {
	return useContext(ContextToken);
  };
