import React, { createContext, useEffect, useState } from 'react';

export const ConsoDictContext = createContext();

export const ConsoDictProvider = ({ children }) => {
  const [sumDict, setSumDict] = useState(JSON.parse(sessionStorage.getItem("sumDict")) || []);

  const addSumConso = (newConsoType, newSumConso) => {
    setSumDict(prevSumDict => {
      const index = prevSumDict.findIndex(item => item.consoType === newConsoType);
      
      if (index !== -1) {
        // Vérifier si la valeur sumConso a changé
        if (prevSumDict[index].sumConso !== newSumConso) {
          // Mettre à jour sumConso si le consoType existe déjà
          return prevSumDict.map((item, i) =>
            i === index ? { ...item, sumConso: newSumConso } : item
          );
        } else {
          // Retourner l'état précédent si rien n'a changé
          return prevSumDict;
        }
      } else {
        // Ajouter un nouveau dictionnaire si le consoType n'existe pas
        return [
          ...prevSumDict,
          { consoType: newConsoType, sumConso: newSumConso }
        ];
      }
    });
  };

  useEffect(()=>{
    sessionStorage.setItem("sumDict", JSON.stringify(sumDict))
  })

  return (
    <ConsoDictContext.Provider value={{ sumDict, addSumConso }}>
      {children}
    </ConsoDictContext.Provider>
  );
};
