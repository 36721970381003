import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

const ApiConnection = ({mail, pwd, Lancer, setLancer, setResponse}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  
  useEffect(() => {
    if (Lancer) {
        fetchData();
    }
  });


   const fetchData = async () => {
     setLoading(true);
    const apiUrl = 'https://prep.goodplanet.org/wp-json/jwt-auth/v1/token';
    
    const data = {
        username: mail,
        password: pwd
    };

    try {
      const result = await axios.post(apiUrl, data);
       // Mettre à jour la réponse de l'API dans le composant parent
       //    setApiResponse(result.data);
       setLancer(false);
       setResponse(result);

       if (result.data.token) {
        sessionStorage.setItem('wptoken', result.data.token);
        sessionStorage.setItem('wpusername', result.data.user_display_name);
        sessionStorage.setItem('wpmail', result.data.user_email);
      }

    } catch (error) {
      console.error("Erreur lors de l'envoi de la requête :", error);
      setResponse(error);
      setLancer(false)
    } finally {
      setLoading(false);
    }
  };
  return (null);
};

export default ApiConnection;
