import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useAuth } from '../Authentification/Auth';
import { IdBCContext } from '../IdBCContext';
import API_BASE_URL from './config';

const AppelAPIBilansCarbone = ({mail, Lancer, setLancer, updateBilansAPIresponse }) => {
    const { token, fetchToken, isTokenExpired } = useAuth();
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { idBC } = useContext(IdBCContext);
  
    useEffect(() => {
        isTokenExpired()
      }, []);
    
    useEffect(() => {
      // Vérifier si le token est disponible avant de faire la requête
      if (token && Lancer) { 
        fetchData();
       }
    }, [token, Lancer]); // Se ré-exécute lorsque `token` change
  
    const fetchData = async () => {
        setLoading(true);
        const columnSelected = `createdon,aps_consovoyage_avion,aps_consovoyage_vehicule,aps_consovoyage_voiture,
        aps_consovoyage_moto,aps_consovoyage_taxi,aps_consovoyage_vtc,aps_consovoyage_train,
        aps_consovoyage_tc,aps_consotrotinettevelo,aps_consoalimentaire,aps_consohabitat,
        aps_consoconsommation_dechetstotaux`

        const emailSelected = `aps_Contact/emailaddress1 eq '${mail}'`

        const params = new URLSearchParams({
            "$select": columnSelected,
            "$filter": emailSelected
        })

        const apiUrl = `${API_BASE_URL}/aps_bilancarbones?${params.toString()}`
        
        const options = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        };
        
        try {
            const resultBilansCarbone = await axios.get(apiUrl, options);
            
            // Mettre à jour la réponse de l'API dans le composant parent
            updateBilansAPIresponse(resultBilansCarbone.data)
            setLancer(false);
        } catch (error) {
        console.error("Erreur lors de l'envoi de la requête :", error);
        setResponse('Erreur lors de la récupération des données.');
        } finally {
        setLoading(false);
        }
    };


    return (null);
    };
  
  export default AppelAPIBilansCarbone;
