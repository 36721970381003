import React, { useState, useRef, useEffect } from 'react';
import './CarreLogo.css';

const CarreLogoInput2Bis = ({
  logo,
  titre,
  titregras,
  currentValeur = '',
  currentValeur2 = '',
  nombre = 2,
  unit = 'kWh',
  unit1 = '€',
  secondeMain,
  verification = false,
  onChange
}) => {
  const [valeur1, setValeur1] = useState(currentValeur);
  const [valeur2, setValeur2] = useState(currentValeur2);
  const [inputVisible, setInputVisible] = useState(true);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const [firstModification, setFirstModification] = useState(false)


  useEffect(() => {
    if(firstModification){
      inputRef1.current.focus();
    }
  }, [valeur1]);

  useEffect(() => {
    if(firstModification){
      inputRef2.current.focus();
    }
  }, [valeur2]);


  const handleChangeValeur1 = (event) => {
    const newValue = event.target.value.replace(/\D/g, ''); // Supprimer les caractères non numériques
    if (verification && newValue !== '' && parseInt(newValue) > 100) {
      setValeur1('100');
    } else {
      setFirstModification(true)
      setValeur1(newValue);
      onChange([0, newValue]);
    }
    setInputVisible(newValue === '');
  };
  
  const handleChangeValeur2 = (event) => {
    const newValue = event.target.value.replace(/\D/g, ''); // Supprimer les caractères non numériques
    if (verification && newValue !== '' && parseInt(newValue) > 100) {
      setValeur2('100');
    } else {
      setFirstModification(true)
      setValeur2(newValue);
      onChange([1, newValue]);
    }
    setInputVisible(newValue === '');
  };

  return (
    <div className='CarreLogoInput2'>
      <div className='GrasCarre None'>{titregras}</div>
      <div className='LogoCarre'>
        {secondeMain ? (
          <div>
            <img src={logo} alt='Image' style={{ opacity: 0.5 }} />
          </div>
        ) : (
          <img src={logo} alt='Image' />
        )}
      </div>
      <div className='titreCarre' style={{ fontSize: '10px' }}>
        {titre}
      </div>
      <div className='titreCarre row'>
        {inputVisible ? (
          <>
            <div className='col-4 inputCL2 offset-1'>
              <input
                ref={inputRef1}
                type='text'
                className="ajout-input"
                value={valeur1}
                onChange={handleChangeValeur1}
                pattern="[0-9]*"
              />
              <div className='KM'>{unit}</div>
            </div>
            <div className='col-1 KM' style={{ fontSize: "10px", padding: "0px", paddingLeft: "5px" }}>ou</div>
            <div className='col-4 inputCL2 offset-1'>
              <input
                ref={inputRef2}
                type='text'
                className="ajout-input"
                value={valeur2}
                onChange={handleChangeValeur2}
                pattern="[0-9]*"
              />
              <div className='KM'>{unit1}</div>
            </div>
          </>
        ) : (
          <div className='col-9 inputCL'>
            <input
              ref={valeur1 ? inputRef1 : inputRef2}
              type='text'
              className="ajout-input"
              value={valeur1 || valeur2}
              onChange={valeur1 ? handleChangeValeur1 : handleChangeValeur2}
              pattern="[0-9]*"
            />
            <div className='KM'>{valeur1 ? unit : unit1}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CarreLogoInput2Bis;
