import React, { createContext, useState, useEffect } from 'react';
import AppelAPIAvion from './Api/AppelAPIAvion';

export const IdBCContext = createContext();

export const IdBCProvider = ({ children }) => {
    const [idBC, setIdBC] = useState(sessionStorage.getItem("idBC") || "");
    const [apiResponse, setApiResponse] = useState("");
    const [consoVoyage, setConsoVoyage] = useState("");
    const [appelVoyage, setAppelVoyage] = useState("");
    const [Lancer, setLancer] = useState(false);

    const [voyage, setVoyage] = useState(
    {
        typeVoyage: '279640000',
        depart: {
        name: 'Paris',
        latitude: 48.8588,
        longitude: 2.3200
        },
        arrive: {
        name: 'Paris',
        latitude: 48.8588,
        longitude: 2.3200
        },
        ajout: { frequence: '1', unit: '279640000' },
        emptyFieldsMessage: null,
        consoVoyage: null
    },
    );

    useEffect(() => {
    // Chaque fois que la valeur de 'idBC' change, on la stocke dans le localStorage
    sessionStorage.setItem('idBC', idBC);
    }, [idBC]);

    useEffect(() => {
    if (!idBC) {
        setLancer(true);
    }
    }, []);

    return (
    <IdBCContext.Provider value={{ idBC, setIdBC }}>
        {children}

        {!idBC && (
        <AppelAPIAvion index={0} voyage={voyage} lancer={Lancer} setLancer={setLancer} setApiResponse={setApiResponse} setConsoVoyage={setConsoVoyage} setAppelVoyage={setAppelVoyage} setIdBC={setIdBC}/>
        )}
    </IdBCContext.Provider>
    );
};
