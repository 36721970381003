import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useAuth } from '../Authentification/Auth';
import { IdBCContext } from '../IdBCContext';
import API_BASE_URL from './config';

const AppelAPITrain = ({index, voyage,kmConnu,km,zoneGeoOui, selectedUnite,setLancer, Lancer, save, setApiResponse, setConsoVoyage, setAppelTrain, filledData, usedUrl, lancerSave, setLancerSave}) => {
  const { token, fetchToken } = useAuth();
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { idBC } = useContext(IdBCContext);

  
  useEffect(() => {
    // Vérifier si le token est disponible avant de faire la requête
    if (token && Lancer) { 
       fetchData();
       setLancer(false);
     }
  }, [token, Lancer]); // Se ré-exécute lorsque `token` change

  useEffect(() => {
    // Vérifier si le token est disponible avant de faire la requête
    if(token && lancerSave){
       fetchDataToSave(filledData, usedUrl);
       setLancerSave(false)
     }
  }, [token, lancerSave]); // Se ré-exécute lorsque `token` change

  const fetchDataToSave = async (filledData, usedUrl) => {
    setLoading(true);
    filledData.save = true
    const options = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    try {
      const result = await axios.post(usedUrl, filledData, options);

    } catch (error) {
      console.error("Erreur lors de l'envoi de la requête :", error);
      setResponse('Erreur lors de la récupération des données.');

    } finally {
      setLoading(false);
      setLancerSave(false) 
    }
  }

   const fetchData = async () => {
     setLoading(true);
    const apiUrlConnu = `${API_BASE_URL}/aps_Ptrain_Global`;
    const apiUrlInconnu= `${API_BASE_URL}/aps_Ptrain_Detail`;
    
    let apiUrl = ''; 

    // Déterminer l'URL de l'API en fonction de la valeur de `kmConnu`
    if (kmConnu === 'oui') {
        apiUrl = apiUrlConnu;
      } else if (kmConnu === 'non') {
        apiUrl = apiUrlInconnu;
      }

    const data = (kmConnu === 'oui')
      ? {
      IdBC: idBC,
      IdPage: "Ptrain",
      kmConnu: "True",
      km: parseFloat(km),
      ZoneGeo: zoneGeoOui,
      frequence : 1,
      unit: selectedUnite,
      save: save
    }:  (kmConnu === 'non') ?
    {
        IdBC: idBC,
        IdPage: "Ptrain",
        ZoneGeo: 279640000, //A voir avec GoodPlnaet et Apsia le parametre ne sert à rien constante pour l'instant mais à surveiller si ca commence a donner des results différents
        typeVoyage: voyage.typeVoyage,
        depart: voyage.depart.name,
        latitudeDepart: voyage.depart.latitude,
        longitudeDepart: voyage.depart.longitude,
        arrivee: voyage.arrive.name,
        latitudeArrivee: voyage.arrive.latitude,
        longitudeArrivee: voyage.arrive.longitude,
        frequence: voyage.ajout.frequence,
        unit: voyage.ajout.unit,
        save: save
      }:null;
    const options = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    
    try {
      const resultTrain = await axios.post(apiUrl, data, options);
      // Stocker à jour l'appel à l'API pour plus tard
      setAppelTrain(index, data, apiUrl)
       // Mettre à jour la réponse de l'API dans le composant parent
       setApiResponse(resultTrain.data);

       if (kmConnu === 'non') {
        setConsoVoyage(index, resultTrain.data.ConsoVoyageTrain)}

       setLancer(false);

    } catch (error) {
      console.error("Erreur lors de l'envoi de la requête :", error);
      setResponse('Erreur lors de la récupération des données.');
    
    } finally {
      setLoading(false);
      setLancer(false);
    }
  };
  return (null);
};

export default AppelAPITrain;
