import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useAuth } from '../Authentification/Auth';
import { IdBCContext } from '../IdBCContext';
import API_BASE_URL from './config';

const AppelAPIDechet = ({compteurDechet, setLancer, Lancer, save, setApiResponse, setAppelDechet,  filledData, usedUrl, lancerSave, setLancerSave}) => {
  const { token, fetchToken, isTokenExpired } = useAuth();
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { idBC } = useContext(IdBCContext);

  useEffect(() => {
    isTokenExpired()
  }, []);

  
  useEffect(() => {
    // Vérifier si le token est disponible avant de faire la requête
    if (token && Lancer) { 
       fetchData();
     }
  }, [token, Lancer]); // Se ré-exécute lorsque `token` change

  useEffect(() => {
    // Vérifier si le token est disponible avant de faire la requête
    if(token && lancerSave){
       fetchDataToSave(filledData, usedUrl);
       setLancerSave(false)
     }
  }, [token, lancerSave]); // Se ré-exécute lorsque `token` change


  const fetchDataToSave = async (filledData, usedUrl) => {
    setLoading(true);
    filledData.save = true
    
    const options = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    
    try {
      const result = await axios.post(usedUrl, filledData, options);

    } catch (error) {
      console.error("Erreur lors de l'envoi de la requête :", error);
      setResponse('Erreur lors de la récupération des données.');

    } finally {
      setLoading(false);
      setLancerSave(false)    
    }
  }

   const fetchData = async () => {
     setLoading(true);
    const apiUrl = `${API_BASE_URL}/aps_Pdechet_Global`;
    
    const dataDechet = {
      IdBC: idBC, 
      IdPage: "Pdechet",
      Mobilier: compteurDechet.Mobilier || 0,
      MobilierScM: compteurDechet.MobilierScM || 0,
      Electronique: compteurDechet.Electronique || 0,
      ElectroniqueScM: compteurDechet.ElectroniqueScM || 0,
      Vetement: compteurDechet.Vetement || 0,
      VetementScM: compteurDechet.VetementScM || 0,
      TriPlastique: compteurDechet.TriPlastique || 0,
      TriPapier: compteurDechet.TriPapier || 0,
      TriDechet: compteurDechet.TriDechet || 0,
      TriCarton: compteurDechet.TriCarton || 0,
      TriVerre: compteurDechet.TriVerre || 0,
      Ampoule: compteurDechet.Ampoule || 0,
      Medicament: compteurDechet.Medicament || 0,
      Elect:compteurDechet.Elect || 0,
      Bricolage:compteurDechet.Bricolage || 0,
      Pile:compteurDechet.Pile || 0,
      save: save
    };
    const options = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    
    try {
      const resultDechet = await axios.post(apiUrl, dataDechet, options);
      // Stocker à jour l'appel à l'API pour plus tard
      setAppelDechet(0, dataDechet, apiUrl)
       // Mettre à jour la réponse de l'API dans le composant parent
       setApiResponse(resultDechet.data);
       setLancer(false);
      //setResponse(result.data);
    } catch (error) {
      console.error("Erreur lors de l'envoi de la requête :", error);
      setResponse('Erreur lors de la récupération des données.');
    } finally {
      setLoading(false);
    }
  };
  
  
  return (null);
};

export default AppelAPIDechet;
