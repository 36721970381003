import React, { createContext, useState } from 'react';

const CacheContextDomicileMaison = createContext();

export const CacheDomicileMaisonProvider = ({ children }) => {
		const [cache, setCache] = useState(JSON.parse(sessionStorage.getItem("cacheDomicileMaison")) || {});

	const setCacheCustom = () => {
		sessionStorage.setItem("cacheDomicileMaison", JSON.stringify(cache))
	}

	return (
		<CacheContextDomicileMaison.Provider value={{ cache, setCache, setCacheCustom }}>
			{children}
		</CacheContextDomicileMaison.Provider>
	);
};

export default CacheContextDomicileMaison;
