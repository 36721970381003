import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "../../../components/Header/index";
import Card from "../../../components/Card/index";
import Footer from '../../../components/Footer/Index';
import ContenueLogo from "../../../components/ContenuCard/ContenueLogo";
import ContenueBouton from "../../../components/ContenuCard/ContenueBouton";
import TitreH2 from '../../../components/TitreH2';
import TitreBG from '../../../components/TitreBG';
import ImageVoiture from "../../../components/img/dom.png";
import StepperLine from '../../../components/StepperLine/index-test';
import LogoAppart from "../../../components/img/Apparte.png";
import LogoMaison from "../../../components/img/maison.png"
import Compteur from '../../../components/Compteur/Compteur';
import CacheContextDomicile from '../../../components/cachescontext/contextDomicile';
import CacheContextDomicileMaison from '../../../components/cachescontext/contextDomicileMaison';

const FormDom = () => {
    const navigate = useNavigate();
    const [cardContenu, setCardContenu] = useState(<ContenueLogo Logo={ImageVoiture} Titre="Votre domicile"/>);

    const { cache: cacheDomicile, setCache: setCacheDomicile  } = useContext(CacheContextDomicile);
    const { cache: cacheDomicileMaison, setCache: setCacheDomicileMaison  } = useContext(CacheContextDomicileMaison);

    // Skip page to ask to fill form or not if already 
    useEffect(() => {
        const isDomicile = Object.keys(cacheDomicile).length != 0
        const isDomicileMaison = Object.keys(cacheDomicileMaison).length != 0
        
        if(isDomicile){
            navigate('/Domicile-1')
        }
        else if(isDomicileMaison){
            navigate('/Domicile-2')
        }
        
    });

    const handleCardClick = () => {
    setCardContenu(<ContenueBouton petiteCards={petiteCards} setPetiteCards={setPetiteCards} TitreP="Votre domicile" SousTitreP="Quel est votre type de logement?" lien = "/Dechet"/>);
    };

    const [petiteCards, setPetiteCards] = useState([
    { label: "Appartement", imageName: LogoAppart, lien:'/Domicile-1' },
    { label: "Maison", imageName: LogoMaison, lien:'/Domicile-2'}
    ]);

    const ajouterPetiteCard = (label, imageName) => {
    const nouvellePetiteCard = { label, imageName };
    setPetiteCards([...petiteCards, nouvellePetiteCard]);
    }

    return (
    <>     
     <div className='page'>     
        <div className="Header">
        <Header />
        </div>
        
        <div className='container-bas container'>
        <StepperLine/>

        <div className='TitreBG'>
        <TitreBG Titre="Calculez vos émissions annuelles" className="titre"/>
        </div>

        <div className="row">
            <div className="col-10 offset-1">
                <TitreH2 Titre="Calculez vos émissions annuelles" className="titre" couleur="#000000"/>
            </div>
            </div>

            <div className="row justify-content-center">
            <div className="col-10">
                <Card contenu={cardContenu} onClick={handleCardClick} color="#024C4A"/>
            </div>
            </div>
            {/* <div className="row justify-content-center">
            <div className="col-8">
                <Footer contenue="Basé sur la dernière version de la Base Empreinte® de l’ADEME." />
            </div>
            </div> */}
        </div>
        </div>
    </>
    );
};

export default FormDom;

