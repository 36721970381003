import React, { createContext, useState } from 'react';

const CacheContextVoiture = createContext();

export const CacheVoitureProvider = ({ children }) => {
		const [cache, setCache] = useState(JSON.parse(sessionStorage.getItem("cacheVoiture")) || {});

	const setCacheCustom = () => {
		sessionStorage.setItem("cacheVoiture", JSON.stringify(cache))
	}

	return (
		<CacheContextVoiture.Provider value={{ cache, setCache, setCacheCustom }}>
			{children}
		</CacheContextVoiture.Provider>
	);
};

export default CacheContextVoiture;
