import React, { useEffect, useState, useContext, useRef } from 'react';
import "./BilanPart.css"
import Header from "../../components/Header/index";
import Footer from "../../components/Footer/Index";
import Graphique from "../../components/Bilan/index"
import CardPetiteDroite from '../../components/CardPetiteDroite';
import SousTitre from '../../components/SousTitre';
import ChiffreSousChiffre from '../../components/CardPetiteDroite/Chiffre';
import ListDeroule from '../../components/Form/ListDeroule';
import TitreH2 from '../../components/TitreH2/index';
import TitreBG from '../../components/TitreBG/index';
import PlanetIcon from "../../components/img/planete-terre.png";
import TShirtIcon from "../../components/img/t-shirt.png";
import ArbreIcon from "../../components/img/arbre.png";
import Ligne1 from '../../components/Bilan/Ligne1';
import Ligne2 from '../../components/Bilan/Ligne2';
import Bouton from '../../components/Form/Bouton';
import CardBilan from '../../components/Bilan/CardBilan';
import Band1 from '../../components/Bilan/Band1';
import Band2 from '../../components/Bilan/Band2';
import { useNavigate } from 'react-router-dom';
import Popup from '../../components/Popup';
import StepperLine from '../../components/StepperLine/index-test';
import { AccountCircle } from '@mui/icons-material';
import html2canvas from 'html2canvas';

import { useFormProgress } from '../../components/FormProgressContext';
import { ConsoDictContext } from '../../components/BilanInter/consoDictContext';
import { useAuth } from '../../components/UserAuthContext'; // Import du contexte
import {useAppelDict} from '../../components/BilanInter/appelDictContext'
import AppelAPIAvion from '../../components/Api/AppelAPIAvion';
import AppelAPIVoiture from '../../components/Api/AppelAPIVoiture';
import AppelAPIMoto from '../../components/Api/AppelAPIMoto';
import AppelAPITaxi from '../../components/Api/AppelAPITaxi';
import AppelAPITrain from '../../components/Api/AppelAPITrain';
import AppelAPITC2 from '../../components/Api/AppelAPITC2';
import AppelAPITC from '../../components/Api/AppelAPITC';
import AppelAPIAlimentaire from '../../components/Api/AppelAPIAlimentaire';
import AppelAPIDomicile from '../../components/Api/AppelAPIDomicile';
import AppelAPIDechet from '../../components/Api/AppelAPIDechet';
import AppelAPIBilanAvion from '../../components/Api/APIBilan/APIBilanAvion';
import AppelAPIBilanVoiture from '../../components/Api/APIBilan/APIBilanVoiture';
import AppelAPIBilanMoto from '../../components/Api/APIBilan/APIBilanMoto';
import AppelAPIBilanTaxi from '../../components/Api/APIBilan/APIBilanTaxi';
import AppelAPIBilanVLoc from '../../components/Api/APIBilan/APIBilanTaxi';
import AppelAPIBilanTrain from '../../components/Api/APIBilan/APIBilanTrain';
import AppelAPIBilanTC from '../../components/Api/APIBilan/APIBilanTC';
import AppelAPIBilanAlimentaire from '../../components/Api/APIBilan/APIBilanAlimentaire';
import AppelAPIBilanDomicile from '../../components/Api/APIBilan/APIBilanDomicile';
import AppelAPIBilanDechet from '../../components/Api/APIBilan/APIBilanDechet';
import AppelAPIBilansCarbone from '../../components/Api/AppelAPIBilansCarbone';


const BilanPart = () => {
    const { logout } = useAuth();
    const { user } = useAuth();
    const navigate = useNavigate();
    const { formStatus, setFormStatus } = useFormProgress();
    const [rapportComparaison, setRapportComparasion] = useState("");
    const graphContainerRef = useRef(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [token, setToken] = useState(localStorage.getItem('wptoken'));
    const [username, setUsername] = useState(sessionStorage.getItem('wpusername'));
    const [userMail, setUserMail] = useState(sessionStorage.getItem('wpmail'));
    const { appelDict, setAppelDict } = useAppelDict();
    const [elementsToRender, setElementsToRender] = useState([]);
    const [lancerSave, setLancerSave] = useState(true);
    const [lancerBilan, setLancerBilan] = useState(true);
    
    const [lancer, setLancer] = useState(false);
    const [bcSent, setbcSent] = useState(false)

    const [displayBilan, setDisplayBilan] = useState(false);
    const [bilansAPIResponse, setBilansAPIResponse] = useState({});

    // État pour gérer la sélection de l'option
    const [selectedBilan, setSelectedBilan] = useState({"createdon":""});
    const [bilanListe, setBilanListe] = useState([]);
    const [motsListDeroule, setMotsListDeroule] = useState([]);

    // Initialisation de la liste des consommations
    const initialConsoList = [
        { consoType: "sumAvion", sumConso: { "Voyage 1": { conso: 0, info: "Consommation de l'avion" } } },
        { consoType: "sumVoiture", sumConso: { "Voyage 1": { conso: 0, info: "Consommation de la voiture" } } },
        { consoType: "sumTrain", sumConso: { "Voyage 1": { conso: 0, info: "Consommation du train" } } },
        { consoType: "sumTC", sumConso: 0 },
        { consoType: "sumAlim", sumConso: 0 },
        { consoType: "sumDechet", sumConso: 0 }
    ];

    const [consolist, setConsoList] = useState(initialConsoList);

    const { sumDict } = useContext(ConsoDictContext);
    let SumDictDisplay = sumDict
    const [graphSumDict, setGraphSumDict] = useState(sumDict);
    const [forceUpdateKey, setForceUpdateKey] = React.useState(0);

    useEffect(() => {
        console.log(userMail)
        if(userMail){
            console.log("Utilisateur connecté")
            setLancer(true)
            setDisplayBilan(true)
        }
        else{
            console.log("Utilisateur déconnecté")
            setDisplayBilan(false)
        }
    }, []); // Dépendance vide : exécute seulement au montage

    const updateBilansAPIresponse = (response) => {
        console.log("réponse de l'api", response)
        setBilansAPIResponse(response);
        prepareBilans(response)
    };

    const prepareBilans = (bilans) => {
        setBilanListe(bilans.value)
        // Créer le dictionnaire
        const dictionary = createDictWithIncrement(bilans.value);
        setMotsListDeroule(dictionary)
    }

    const createDictWithIncrement = (list) => {
        const dictionary = {};

        list.forEach((isoDate) => {
          let formattedDate = formatDate(isoDate.createdon);
          let uniqueKey = formattedDate;
          let counter = 1;
      
          // Incrémenter si la clé existe déjà
          while (dictionary.hasOwnProperty(uniqueKey)) {
            uniqueKey = `${formattedDate} n°${counter}`;
            counter++;
          }
      
          // Ajouter la clé unique au dictionnaire
          dictionary[uniqueKey] = isoDate.createdon;
        });
      
        return dictionary;
    };

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
      
        // Extraire les parties de la date
        const day = String(date.getDate()).padStart(2, '0'); // Ajoute un "0" devant si nécessaire
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
        const year = date.getFullYear();
      
        return `Formulaire du ${day}-${month}-${year}`;
    };

    // Gestion de la sélection
    const handleListDerouleChange = (selectedValue) => {
        if(bilanListe.length != 0){
            const bilanSelected = motsListDeroule[selectedValue]
            console.log(bilanSelected)
            console.log("Liste déroulé change")
            const selected = bilanListe.find((item) => item.createdon == bilanSelected); // Trouver l'objet correspondant
            setSelectedBilan(selected);

            const currentConsoList = prepareSelectedBilan(selected)

            setConsoList(currentConsoList)
            setGraphSumDict(currentConsoList)
            setForceUpdateKey((prevKey) => prevKey + 1)
            return currentConsoList
        }
        else{
            console.log("Liste des bilans pas encore définie")
        }
    };

    SumDictDisplay = graphSumDict

    const prepareSelectedBilan = (selectedBilan) => {
        if (selectedBilan !== undefined){
            const consoAvion = selectedBilan.aps_consovoyage_avion
            const consoVoiture = selectedBilan.aps_consovoyage_moto + selectedBilan.aps_consovoyage_taxi + selectedBilan.aps_consovoyage_voiture + selectedBilan.aps_consovoyage_vtc
            const consoVoitureTotalVehicule = selectedBilan.aps_consovoyage_vehicule
            const consoTrain = selectedBilan.aps_consovoyage_train
            const consoTC = selectedBilan.aps_consovoyage_tc + selectedBilan.aps_consotrotinettevelo
            const consoAlim = selectedBilan.aps_consoalimentaire
            const consoDomicile = selectedBilan.aps_consohabitat
            const consoDechet = selectedBilan.aps_consoconsommation_dechetstotaux


            // Initialisation de la liste des consommations
            const currentConsoList = [
                { consoType: "sumAvion", sumConso: { "Voyage 1": { conso: consoAvion, info: "Consommation de l'avion" } } },
                { consoType: "sumVoiture", sumConso: { "Voyage 1": { conso: consoVoiture, info: "Consommation de la voiture" } } },
                { consoType: "sumTrain", sumConso: { "Voyage 1": { conso: consoTrain, info: "Consommation du train" } } },
                { consoType: "sumTC", sumConso: consoTC },
                { consoType: "sumAlim", sumConso: consoAlim },
                { consoType: "sumDomicile", sumConso: consoDomicile },
                { consoType: "sumDechet", sumConso: consoDechet }
            ];

            return currentConsoList
        }
    }


    useEffect(() => {
        // Cette fonction s'exécute une seule fois, au premier montage du composant
        if(!sessionStorage.getItem("bcSent")){
            
            console.log("Début du traitement du dictionnaire");
            console.log(appelDict)
            const newElements = [];
            Object.entries(appelDict).forEach(([classe, elements]) => {
                console.log(`Classe : ${classe}`)
                elements.forEach((appelAPI, index) => {
                    let categorie = ""
                    let apiUrl = ""
                    let data = ""
                    Object.entries(appelAPI).forEach(([classeAppel, appelData]) => {
                        if (classeAppel == "apiUrl"){
                            apiUrl = appelData
                        }
                        else if (classeAppel != "apiUrl"){
                            categorie = classeAppel
                            data = appelData
                        }
                    })
                    console.log(categorie)

                    if(categorie == "appelAvion"){
                        setLancerSave(true)
                        newElements.push(<AppelAPIAvion filledData={data} usedUrl={apiUrl} lancerSave={lancerSave} setLancerSave={setLancerSave} />);
                    }
                    if(categorie == "appelVoiture"){
                        setLancerSave(true)
                        newElements.push(<AppelAPIVoiture filledData={data} usedUrl={apiUrl} lancerSave={lancerSave} setLancerSave={setLancerSave} />);
                    }
                    if(categorie == "appelMoto"){
                        setLancerSave(true)
                        newElements.push(<AppelAPIMoto filledData={data} usedUrl={apiUrl} lancerSave={lancerSave} setLancerSave={setLancerSave} />);
                    }
                    if(categorie == "appelTaxi"){
                        setLancerSave(true)
                        newElements.push(<AppelAPITaxi filledData={data} usedUrl={apiUrl} lancerSave={lancerSave} setLancerSave={setLancerSave} />);
                    }
                    if(categorie == "appelTrain"){
                        setLancerSave(true)
                        newElements.push(<AppelAPITrain filledData={data} usedUrl={apiUrl} lancerSave={lancerSave} setLancerSave={setLancerSave} />);
                    }
                    if(categorie == "appelTC"){
                        setLancerSave(true)
                        newElements.push(<AppelAPITC filledData={data} usedUrl={apiUrl} lancerSave={lancerSave} setLancerSave={setLancerSave} />);
                    }
                    if(categorie == "appelTC2"){
                        setLancerSave(true)
                        newElements.push(<AppelAPITC2 filledData={data} usedUrl={apiUrl} lancerSave={lancerSave} setLancerSave={setLancerSave} />);
                    }
                    if(categorie == "appelAlimentaire"){
                        setLancerSave(true)
                        newElements.push(<AppelAPIAlimentaire filledData={data} usedUrl={apiUrl} lancerSave={lancerSave} setLancerSave={setLancerSave} />);
                    }
                    if(categorie == "appelDomicile"){
                        setLancerSave(true)
                        newElements.push(<AppelAPIDomicile filledData={data} usedUrl={apiUrl} lancerSave={lancerSave} setLancerSave={setLancerSave} />);
                    }
                    if(categorie == "appelDomicileMaison"){
                        setLancerSave(true)
                        newElements.push(<AppelAPIDomicile filledData={data} usedUrl={apiUrl} lancerSave={lancerSave} setLancerSave={setLancerSave} />);
                    }
                    if(categorie == "appelDechet"){
                        setLancerSave(true)
                        newElements.push(<AppelAPIDechet filledData={data} usedUrl={apiUrl} lancerSave={lancerSave} setLancerSave={setLancerSave} />);
                    }
                    });
            });
            // Call all api bilan to save altogether in Apsia
            saveBilan()
            newElements.push(<AppelAPIBilanAvion Lancer={lancerBilan} setLancerSave={setLancerBilan} save={true} />)
            setLancerBilan(true)
            newElements.push(<AppelAPIBilanVoiture Lancer={lancerBilan} setLancerSave={setLancerBilan} save={true} />)
            setLancerBilan(true)
            newElements.push(<AppelAPIBilanMoto Lancer={lancerBilan} setLancerSave={setLancerBilan} save={true} />)
            setLancerBilan(true)
            newElements.push(<AppelAPIBilanTaxi Lancer={lancerBilan} setLancerSave={setLancerBilan} save={true} />)
            setLancerBilan(true)
            newElements.push(<AppelAPIBilanVLoc Lancer={lancerBilan} setLancerSave={setLancerBilan} save={true} />)
            setLancerBilan(true)
            newElements.push(<AppelAPIBilanTrain Lancer={lancerBilan} setLancerSave={setLancerBilan} save={true} />)
            setLancerBilan(true)
            newElements.push(<AppelAPIBilanTC Lancer={lancerBilan} setLancerSave={setLancerBilan} save={true} />)
            setLancerBilan(true)
            newElements.push(<AppelAPIBilanAlimentaire Lancer={lancerBilan} setLancerSave={setLancerBilan} save={true} />)
            setLancerBilan(true)
            newElements.push(<AppelAPIBilanDomicile Lancer={lancerBilan} setLancerSave={setLancerBilan} save={true} />)
            setLancerBilan(true)
            newElements.push(<AppelAPIBilanDechet Lancer={lancerBilan} setLancerSave={setLancerBilan} save={true} />)

            setbcSent(true)
            setElementsToRender(newElements);
            sessionStorage.setItem("bcSent", true)
            console.log("Fin du traitement du dictionnaire");
        }
    }, []); // Dépendance vide : exécute seulement au montage

    const saveBilan = () => {
        console.log("je save les bilans")
        
    }

    
    const EQUIVALENCES = {
    tourDuMonde: {constante: 0.218, unit: 40000}, // KgCO2eq/km
    tShirt: {constante: 6.43, unit: 1000}, // KgCO2eq/an
    absorbtionArbre: {constante: 25, unit: 100} //KgCO2e/an
    }
    const imgLinks = {
    img1: "https://www.goodplanet.info/wp-content/uploads/2022/04/001-MA1605N-3310.jpg",
    img2: "https://www.goodplanet.org/wp-content/uploads/2017/04/Paralaxe-INDE-1024x512.jpg",
    img3: "https://www.goodplanet.org/wp-content/uploads/2023/11/GoodPlanet-300-%C3%97-250-px-2.gif",
    }
    const links = {
    lnk1: "https://www.goodplanet.info/2022/04/04/lessentiel-nouveau-rapport-giec-solutions-attenuer-le-changement-climatique/",
    lnk2: "https://www.goodplanet.org/fr/programmes/action-carbone-solidaire/",
    lnk3: "https://www.goodplanet.org/fr/institut-de-formation-myplanet/",
    }
    const textLinks = {
    txt1: "L’essentiel sur le nouveau rapport du Giec consacré aux solutions pour réduire les émissions de gaz à effet de serre et atténuer le changement climatique",
    txt2: "Découvrir les projets de réduction des émissions de GES/d'action pour le climat de FGP",
    txt3: "Se former vous pour agir et réduire l'impact de votre activité professionnelle",
    }

    const franceNumbers = {
    consoTotalAvion: 0.42,
    consoTotalTC: 0.18,
    consoTotalTrain: 0.05,
    consoTotalVoiture: 2.1,
    consoTotalDomicile: 1.3,
    consoTotalDechet: 1.15,
    consoTotalPlacement: 0.09,
    consoTotalAlimentation: 2.45,
    consoTotalServiceP: 1.3
    };

    const generateGraphImage = async () => {
    const node = graphContainerRef.current;
    const scale = 2;
    if (node) {
        const canvas = await html2canvas(node, {
        scale,
        useCORS: true,
        height: node.clientHeight + 100,
        });
        const imageUrl = canvas.toDataURL('image/jpeg');
        setImageUrl(imageUrl);
    }
    return imageUrl;
    };

    const sumFrance = Object.values(franceNumbers).reduce((acc, curr) => acc + curr, 0);

    const getConsoFromDict = (graphSumDict, consoType) => {
        const voyages = graphSumDict.find(item => item.consoType === consoType);

        if (!voyages || !voyages.sumConso) {

            return (0).toFixed(1);

        } else if (typeof voyages.sumConso === 'number' || typeof voyages.sumConso === 'string') {

            return voyages.sumConso;

        } else {
            const totalConso = Object.values(voyages.sumConso).reduce((total, voyage) => {
            return total + (voyage.conso ? parseFloat(voyage.conso) : 0)
            }, 0);

            return totalConso.toFixed(1)
        }
    };

    const getTotalConsumption = (graphSumDict) => {
    return (1.3 + graphSumDict.reduce((total, item) => {
        return total + (getConsoFromDict(graphSumDict, item.consoType) ? parseFloat(getConsoFromDict(graphSumDict, item.consoType)) : 0);
    }, 0)).toFixed(1);
    };
    const totalConsumption = getTotalConsumption(graphSumDict);

    const handleButtonClick = (event) => {
    event.preventDefault();
    navigate('/bilan-2');
    window.scrollTo(0, 0);
    };

    const handleButtonClickBilanActuel = (event) => {
        event.preventDefault();
        setGraphSumDict(sumDict)
        setForceUpdateKey((prevKey) => prevKey + 1)

    };

    const handleButtonClickBilanFinal = (event) => {
    event.preventDefault();
    navigate('/bilan-4');
    window.scrollTo(0, 0);
    };

    const handleButtonClickBilanFinancier = (event) => {
    event.preventDefault();
    navigate('/placement');
    }

    const [isPopupOpen, setPopupOpen] = useState(false);

    const handleButtonClickConnexion = (event) => {
    event.preventDefault();
    navigate("/Connection")
    }

    const handleButtonClickDeconnexion = (event) => {
        event.preventDefault();
        setDisplayBilan(false)
        setGraphSumDict(sumDict)
        setForceUpdateKey((prevKey) => prevKey + 1)
        localStorage.removeItem('wptoken');
        sessionStorage.removeItem('wpusername');
        sessionStorage.removeItem('wpmail');
        logout()
        setToken(null);
        setUsername(null);
    }

    useEffect(() => {
    setPopupOpen(true);
    }, []);

    // useEffect(() => {
    //     generateGraphImage();
    // })


    const handlePopupClose = () => {
    setPopupOpen(false);
    };

    useEffect(() => {
    // Mettre à jour le statut du formulaire
    setFormStatus({
        ...formStatus,
        bilan: 'en cours',
    });
    }, []);

    useEffect(() => {
    setToken(localStorage.getItem('wptoken'));
    setUsername(sessionStorage.getItem('wpusername'));
    }, []);

    const getEquivalence = (key, totalConsumption) => {
    const consoTotale = totalConsumption;
    const consoConstante = EQUIVALENCES[key].constante;
    const equi = (consoTotale * 1000 / consoConstante) / EQUIVALENCES[key].unit;
    return equi.toFixed(1);
    }

    return (
    <div className='page'>     
        <div style={{zIndex : "999"}}>
        {!username && (
        <Popup isOpen={isPopupOpen} onClose={handlePopupClose}>
            <div className='row col-12 d-flex justify-content-between align-items-center'>
            <div className="col-8">
                <TitreH2 Titre="Bilan de vos émissions annuelles" className="titre" couleur="#000000" />
            </div>
            {username ? (
                <div className="col-auto d-flex align-items-center">
                    <div className='circle-bilan-disconnect' onClick={handleButtonClickDeconnexion}>
                    <AccountCircle style={{ fontSize: '40px', color: 'green' }} />
                    <div className='text-bilan'>Bienvenue {username}</div>
                    <Bouton titre="Déconnexion" onClick={handleButtonClickDeconnexion} />
                    </div>
                </div>
            ) : (
                <div className="col-auto d-flex align-items-center">
                <div className='circle-bilan' onClick={handleButtonClickConnexion}>
                    <AccountCircle style={{ fontSize: '40px' }} />
                    <div className='text-bilan'>Connectez-vous ou créez un compte</div>
                </div>
                </div>
            )}
            </div>
            <div className="row col-10 offset-1" style={{marginTop : "10vh"}}>
                    <CardPetiteDroite
                    className="emission-card"
                    color={{ backgroundColor: "black" }}                
                    contenu={
                        <div >
                        <ChiffreSousChiffre
                        Titre={"Vos émissions"}
                        Chiffre={totalConsumption}
                        SousChiffre={"Tonnes de CO₂"}
                        style={{
                            padding:"1px",
                            height: "65%",     
                            marginTop: "1px",
                        }}
                        />
                        </div>
                    }
                    />
                </div>
            <div style={{
            display: 'flex',
            justifyContent: 'space-around',
            width: '100%',
            }}>
            <div className='row col-12 d-flex justify-content-center'>
            {/* <div className='col-lg-6 col-12 d-flex justify-content-center'>
                <Bouton titre="Réduire mes émissions" onClick={handleButtonClick} /> 
            </div> */}
            <div className='col-lg-6 col-12 d-flex justify-content-center'>
                <Bouton titre="Voir mon bilan sans créer de compte" onClick={handlePopupClose} />
            </div>
            </div>
            </div>
        </Popup>
        )}
        </div>
        <div className="Header">
        <Header />
        </div>
        
        <div className='container-bas container'>
        <StepperLine />
        
        <div className='col-10 offset-1'>
        <div className='TitreBG'>
        <TitreBG Titre="Calculez vos émissions annuelles" className="titre"/>
        </div>
        <div className="row">

            <div className="col-10">
                <TitreH2 Titre="Bilan de vos émissions annuelles" className="titre" couleur="#000000"/>
            </div>
            
        </div>

        <div className="row">

            <div>
                {elementsToRender}
            </div>

            <div className="col-9">


                <h1 className = "h31">Répartition de vos émissions</h1>

                <div ref={graphContainerRef}>

                <SousTitre SousTitre="Mes émissions " />

                <p className = "p1">A vos émissions annuelles, 1,3 tCO₂ s’ajoutent liées au fonctionnement de nos services publics. </p>

                <Graphique key={forceUpdateKey} sumDict={SumDictDisplay} franceNumbers={franceNumbers} />
                </div>
            </div>


            <div className="col-3">
                <div className="row col-12" style={{marginTop : "3vh"}}>
                <CardPetiteDroite
                    color={{ backgroundColor: "black", height : "150px" }}
                    taille={{ height : "150px"}}                    
                    contenu={
                    <ChiffreSousChiffre
                        Titre={"Vos émissions"}
                        Chiffre={totalConsumption}
                        SousChiffre={"Tonnes de CO₂"}
                        style={{
                        height: "80%",     
                        marginTop: "10px",
                        fontSize:"12px",
                        }}
                    />
                    }
                />
                </div>

                <div className="row justify-content-center">
                {username ? (
                    <div className="col-auto d-flex align-items-center">
                    <div className='circle-bilan-disconnect' onClick={handleButtonClickDeconnexion}>
                        <AccountCircle style={{ fontSize: '40px', color: 'green' }} />
                        <div className='text-bilan'>Bienvenue {username}</div>
                        <Bouton titre="Déconnexion" onClick={handleButtonClickDeconnexion} />
                    </div>
                    </div>
                ) : (
                    <div className="col-auto d-flex align-items-center">
                    <div className='circle-bilan' onClick={handleButtonClickConnexion}>
                        <AccountCircle style={{ fontSize: '40px' }} />
                        <div className='text-bilan'>Connectez-vous ou créez un compte</div>
                    </div>
                    </div>
                )}

                {displayBilan && (
                    <div className="col-auto d-flex align-items-center">
                        <Bouton titre = "Afficher mon bilan actuel"    onClick={handleButtonClickBilanActuel}/>
                    </div>
                )}

                </div>

                {displayBilan && (
                    <AppelAPIBilansCarbone mail={userMail} setLancer={setLancer} Lancer={lancer} updateBilansAPIresponse={updateBilansAPIresponse}/> 
                )}

                {displayBilan && (
                <div className="row offset-1" style={{marginTop : "5vh"}}>
                    <ListDeroule
                    titre="Se comparer à"
                    // value="selectedBilan?.createdon || "
                    onChange={handleListDerouleChange}
                    // text={motsListDeroule[0]}
                    storageKey="selectedBilan"
                    mots = {Object.keys(motsListDeroule)}
                    />
                </div>
                )}

                

                {selectedBilan && displayBilan && (
                    <div>
                    <h4>Formulaire sélectionné :</h4>
                    <p>Date : {selectedBilan.createdon}</p>
                    </div>
                )}

                <div className="row col-12"    style={{marginTop : "3vh"}}>
                <CardPetiteDroite
                    color={{ backgroundColor: "black", height : "150px" }}
                    taille={{ height : "150px"}}
                    contenu={
                    <ChiffreSousChiffre
                        Titre={"Moyenne française"}
                        Chiffre={sumFrance}
                        SousChiffre={"Tonnes de CO₂"}                    
                        style={{
                            height: "80%",     
                            marginTop: "10px",
                            fontSize:"12px",
                            }}
                    />
                    }
                />
                </div>
                <div 
                className="row offset-1"
                style={{
                marginTop : "3vh",
                fontFamily: 'Montserrat',
                fontStyle: "normal",
                fontWeight: "800",
                fontSize: "10px",
                lineHeight: "12px",
                fontVariant: "all-small-caps",
                }}    
                >

                <Bouton titre = "Mon empreinte totale"    onClick={handleButtonClickBilanFinal}/>
                <Bouton titre = "Calculer mon empreinte financière"    onClick={handleButtonClickBilanFinancier}/>

                </div>
            </div>

            </div>

            <div className="row" style={{marginTop : "8vh", backgroundColor: "#F5F5F5"}}>
                <div className="col-4">
                <Ligne1 logo = {PlanetIcon} titre = {"Tour du monde en voiture thermique"} titregras={"tour(s) du monde"} floatNumber={getEquivalence("tourDuMonde", totalConsumption)} />
                </div>
                <div className="col-4"> 
                <Ligne1 logo = {TShirtIcon} titre = {"T-shirts en coton produits"} titregras={"milliers de t-shirt(s)"} floatNumber={getEquivalence("tShirt", totalConsumption)}/>
                </div>
                <div className="col-4">
                <Ligne1 logo = {ArbreIcon} titre = {"Arbres nécessaires pour absorber"} titregras={"centaine(s) d'arbres"} floatNumber={getEquivalence("absorbtionArbre", totalConsumption)}/>
                </div>
            </div>

            <div className="row justify-content-center" style={{marginTop : "5vh"}}>
                <div className="col-4">
                <Bouton titre="Réduire mes émissions" onClick={handleButtonClick}/>
                </div>
                <div className="col-4">
                <Bouton titre = {"VOIR LE Bilan détaillé"} onClick={handleButtonClickBilanFinal}/>
                </div>
            </div>

            <div className='row col-12' style={{marginTop : "1vh"}}>
                <Band1 generateGraphImage={generateGraphImage} totalConsumption={totalConsumption} />
            </div>

            <div className="row justify-content-center" style={{marginTop : "5vh"}}>
                <div className="col-12">
                <h1 className = "h31">Contribution à la neutralité carbone</h1>
                </div>
                <div className="col-12">
                <p className='p1'>
                L’Accord de Paris est un traité international adopté par 196 parties lors de la COP21 de Paris en 2015. L’objectif est de limiter le réchauffement climatique en deçà de 2°C, et de tout faire pour le maintenir à 1,5°C par rapport à l’ère préindustriel. Pour se faire, Etat, individus et entreprises doivent travailler main dans la main pour réduire les émissions de gaz à effet de serre et augmenter les puits de carbone dans l’objectif d’atteindre une neutralité carbone mondial.    
                </p>
                </div>
            </div>

            <div className='row col-12'>
                <CardBilan/>
            </div>

            <div className="row" style={{marginTop : "10vh"}}>
                <div className="col-4">
                <Ligne2 text = {textLinks.txt1} lien={links.lnk1} image={imgLinks.img1}/>
                </div>
                <div className="col-4">
                <Ligne2 text = {textLinks.txt2} lien={links.lnk2} image={imgLinks.img2}/>
                </div>
                <div className="col-4">
                <Ligne2 text = {textLinks.txt3} lien={links.lnk3} image={imgLinks.img3}/>
                </div>
            </div>

            <div className='row col-12' style={{marginTop : "5vh"}}>
                <Band2/>
            </div>
            
        </div>

        <div className="row justify-content-center">
            <div className="col-8">
                {/* Pied de page */}
                <Footer contenue="Cet outil est une version en test, pour nous partager vos remarques & suggestions vous pouvez nous écrire à entreprise@goodplanet.org" />
            </div>
            </div>

        </div>
    </div>
    );
};

export default BilanPart;

