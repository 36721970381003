import React, { createContext, useState } from 'react';

const CacheContextVTC = createContext();

export const CacheVTCProvider = ({ children }) => {
		const [cache, setCache] = useState(JSON.parse(sessionStorage.getItem("cacheVTC")) || {});

	const setCacheCustom = () => {
		sessionStorage.setItem("cacheVTC", JSON.stringify(cache))
	}

	return (
		<CacheContextVTC.Provider value={{ cache, setCache, setCacheCustom }}>
			{children}
		</CacheContextVTC.Provider>
	);
};

export default CacheContextVTC;
