import React, { createContext, useContext, useState } from 'react';

// Crée un contexte d'utilisateur
const UserAuthContext = createContext();

export const UserAuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // `null` signifie non connecté

  const login = (userData) => {
    setUser(userData); // Met à jour l'utilisateur après la connexion
  };

  const logout = () => {
    setUser(null); // Réinitialise l'utilisateur
  };

  return (
    <UserAuthContext.Provider value={{ user, login, logout }}>
      {children}
    </UserAuthContext.Provider>
  );
};

// Hook pour accéder au contexte
export const useAuth = () => useContext(UserAuthContext);
