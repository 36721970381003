import React, { useState } from 'react';
import "./checkbox.css"

const CheckboxChoixUnique = ({ titre, rep1, rep2, onChange, value}) => {
  const [choix, setChoix] = useState(value);

  const handleCheckboxChange = (event) => {
    setChoix(event.target.value);
    onChange(event.target.value === "oui");
  };

  return (
    <div className="checkbox row">
      {/* <SousTitre SousTitre={titre} place="start" /> */}
      <div className='h61'>{titre}</div>
      <div className='col-12'>
      <label>
        <input
          type="checkbox"
          name="choix"
          value="oui"
          checked={choix === "oui"}
          onChange={handleCheckboxChange}
        />
        <span className="checkmark"></span>
        {rep1}
      </label>
      </div>

      <div className='col-12'>
      <label>
        <input
          type="checkbox"
          name="choix"
          value="non"
          checked={choix === "non"}
          onChange={handleCheckboxChange}
        />
        <span className="checkmark"></span>
        {rep2}
      </label>
    </div>
    </div>
  );
}

export default CheckboxChoixUnique;
