import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from '../Input';
import Bouton from '../Bouton';
import ListDeroule from '../ListDeroule';
import 'bootstrap/dist/css/bootstrap.min.css';
import SousTitre from '../../SousTitre';
import './form.css';
import Ajout from '../Ajout';
import BilanInter from '../../BilanInter';
import CheckboxChoixUnique from '../checkBox/checkbox';
import InputFieldNb from '../InputNombre/index';
import CheckboxAjout from '../checkBoxAjout/CheckboxAjout';
import AjoutPerso from '../Ajout/index-personne';
import { Button } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CheckboxChoixUnique3 from '../checkBox/checkbox-3';
import AppelAPITC from '../../Api/AppelAPITC';
import { type } from '@testing-library/user-event/dist/type';
import {useAppelDict} from '../../BilanInter/appelDictContext'
import CacheContextTC from '../../../components/cachescontext/contextTC';
import { useFormProgress } from '../../FormProgressContext';

function FormTC({ onSubmit }) {
  const { cache, setCache, setCacheCustom } = useContext(CacheContextTC);

  const navigate = useNavigate();
  const [valeurInput, setValeurInput] = useState(() => cache.valeurInput || '1');
  const [formSubmitted, setFormSubmitted] = useState(() => cache.formSubmitted || false);
  const [consommationConnue, setConsommationConnue] = useState(() => cache.consommationConnue || false);
  const [typeCarburant, setTypeCarburant] = useState(() => cache.typeCarburant || '');
  const [typeKilo, setTypeKilo] = useState(() => cache.typeKilo || 'oui');
  const [consommation, setConsommation] = useState(() => cache.consommation || '');
  const [kilometrageConnu, setKilometrageConnu] = useState(() => cache.kilometrageConnu || false);
  const { formStatus, setFormStatus } = useFormProgress();
  const [lancer, setLancer] = useState(() => cache.lancer || false);
  const [apiResponse, setApiResponse] = useState(null); // État pour stocker la réponse de l'API
  const [transport, setTransport] = useState(() => cache.transport || 279640002);
  const [makeApiCall, setMakeApiCall] = useState(false)

  const { appelDict, setAppelDict } = useAppelDict();

  // Méthode pour stocker les appels fait à voiture
  const updateAppelTC = (index, appelTC, apiUrl) => {
		setAppelTC((prevAppelTC) => {
            const newAppelTC = [...prevAppelTC];
			      newAppelTC[index] = { ...newAppelTC[index], appelTC: appelTC , apiUrl: apiUrl};
            addToAppelDict(newAppelTC)
			      return newAppelTC;
		});
  };

  // Etat pour stocker les voyages
  const [appelVoyages, setAppelTC] = useState(() => [
    {
  },
  ]);

  // Méthode pour stocker tous les appels API 
  const addToAppelDict = (appelsTC) => {
    setAppelDict(prev => {
        const newAppelDict = { ...prev, 'TC': appelsTC };
        console.log("Appel Dict", newAppelDict);
        return newAppelDict;
    });
  };

  useEffect(() => {
    setFormStatus({
        ...formStatus,
        tc: 'en cours',
    });
   }, []);

  const handleTransportChange = (newTransport) => {
 
    let transportValue;
    switch (newTransport) {
      case 'Tram':
        transportValue = 279640000;
        break;
      case 'Métro/RER':
        transportValue = 279640001;
        break;
      case 'Bus':
        transportValue = 279640002;
        break;
      case 'Train':
        transportValue = 279640003;
        break;
      case 'Autocar':
        transportValue = 279640004;
        break;  
      default:
        transportValue = newTransport; // Défaut à bus n'est pas reconnu
    }
    setTransport(transportValue); // Met à jour le transport sélectionnée dans l'état

  };

  


  const [selectedUnite, setSelectedUnite] = useState(() => cache.selectedUnite || 279640000); // État pour stocker l'unité sélectionnée

  // Fonction pour récupérer et mapper l'unité sélectionnée
 
  const handleUniteChange = (newUnite) => {
    //console.log('je suis dans handleUniteChange');
    let unitValue;
    switch (newUnite) {
      case 'an':
        unitValue = 279640000;
        break;
      case 'mois':
        unitValue = 279640001;
        break;
      case 'semaine':
        unitValue = 279640002;
        break;
      default:
        unitValue = 279640000; // Défaut à an si newUnite n'est pas reconnu
    }
    setSelectedUnite(unitValue); // Met à jour l'unité sélectionnée dans l'état
    console.log(newUnite);
  };



  // Fonction pour mettre à jour l'état de la réponse de l'API
  const updateApiResponse = (response) => {
    setApiResponse(response);
  };

  const updateConsoVoyage = (index, consoVoyage) => {
    setVoyages((prevVoyages) => {
      const newVoyages = [...prevVoyages];
      newVoyages[index] = { ...newVoyages[index], consoVoyage: consoVoyage };
      return newVoyages;
    });
  };


  const [voyages, setVoyages] = useState(() => cache.voyages || [
    {
        typeVoyage: 279640000,
        typeTransport:279640000,
        depart: {
          name: '',
          latitude: null,
          longitude: null
        },
        arrive: {
          name: '',
          latitude: null,
          longitude: null
        },
        ajout: { frequence: '', unit: 'an' },
        ajoutPerso : '',
        consoVoyage : null
    },
  ]);

  // Update cache when voyages change
  useEffect(() => {
  setCache(prevCache => ({
    ...prevCache,
    valeurInput,
    formSubmitted,
    consommationConnue,
    typeCarburant,
    typeKilo,
    consommation,
    kilometrageConnu,
    lancer,
    transport,
    selectedUnite,
    voyages,
  }));
  setCacheCustom()
  }, [
    valeurInput,
    formSubmitted,
    consommationConnue,
    typeCarburant,
    typeKilo,
    consommation,
    kilometrageConnu,
    lancer,
    transport,
    selectedUnite,
    voyages,
  ]);

  useEffect(() => {
    let sumConsoVoyage = 0;

    if (apiResponse && typeKilo == "non" && voyages[voyages.length - 1].consoVoyage != null) 
      {
        voyages.forEach((voyage) => (
          sumConsoVoyage += voyage.consoVoyage
        ))
        navigate(`/TC-3?consoTC1=${sumConsoVoyage}`)
      } 
      else if (apiResponse && typeKilo == "oui")
      {
        sumConsoVoyage = apiResponse.ConsoTC
        navigate(`/TC-3?consoTC1=${sumConsoVoyage}`)
      }
    }, [apiResponse, typeKilo, voyages, navigate])

  const handleDeleteVoyage = (index) => {
    setVoyages((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      updatedVoyages.splice(index, 1);
      return updatedVoyages;
    });
  };

  const addVoyage = useCallback(() => {
    setVoyages((prevVoyages) => [
      ...prevVoyages,
      {
        typeVoyage: 279640000,
        typeTransport: 279640000,
        depart: {
          name: '',
          latitude: null,
          longitude: null
        },
        arrive: {
          name: '',
          latitude: null,
          longitude: null
        },
        ajout: { frequence: '', unit: 'an' },
        ajoutPerso : '',
        consoVoyage : null
      },
    ]);
  }, []);

  const isFormValid = voyages.every((voyage) => {
    if (typeKilo === "non") {
        // Le cas ou il y a un voyage à remplir
        return voyage.typeVoyage && voyage.depart.name && voyage.arrive.name && voyage.ajout.frequence;
    } else {
        return transport && valeurInput && selectedUnite;
    }
});
  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    console.log(formSubmitted);
    
  };








  const handleConsommationConnueChange = (value) => {
    setConsommationConnue(value);
  };

  const handleChangeVoyage = useCallback((index, field, value) => {
    setVoyages((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      const voyage = updatedVoyages[index];
      if (voyage) {
        if (field === 'typeTransport') {
          let typeTransportValue;
          switch (value) {
            case 'Tram':
              typeTransportValue = 279640000;
              break;
            case 'Metro/RER':
              typeTransportValue = 279640001;
              break;
            case 'Bus':
              typeTransportValue = 279640002;
              break;
            case 'Train':
              typeTransportValue = 279640003;
              break;
            case 'Autocar':
              typeTransportValue = 279640004;
              break;
            default:
              typeTransportValue = 279640000; // Défaut tram si le type de transport n'est pas reconnu
          }
          voyage[field] = typeTransportValue;
        } else {
          voyage[field] = value; // Si ce n'est pas 'typeTransport', mettre à jour directement avec la valeur fournie
        }
      }
      return updatedVoyages;
    });
  }, []);

  const handleChangeAjout = useCallback((index, values) => {
    setVoyages((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      const voyage = updatedVoyages[index];
      if (voyage) {
        // Récupérer les valeurs de fréquence et unité
        const { frequence, unit } = values;
        
        // Transformer unit selon les conditions
        let unitValue;
        switch (unit) {
          case 'an':
            unitValue = 279640000;
            break;
          case 'mois':
            unitValue = 279640001;
            break;
          case 'semaine':
            unitValue = 279640002;
            break;
          default:
            unitValue = 279640000; // Défaut à an si unit n'est pas reconnu
        }
        
        // Mettre à jour les valeurs
        voyage.ajout = { frequence, unit: unitValue };
      }
      return updatedVoyages;
    });
  }, [setVoyages]);


  const handleChangeAjoutPerso = useCallback((index, values) => {
    setVoyages((prevVoyages) => {
      const updatedVoyages = [...prevVoyages];
      const voyage = updatedVoyages[index];
      if (voyage) {
        voyage.ajoutPerso = values;
      }
      return updatedVoyages;
    });
  }, []);

  const handleButtonClick = (event) => {
    event.preventDefault();
    setLancer(true);
    setMakeApiCall(true);
    // navigate('/TC-3'); // Ajout de la navigation après la soumission du formulaire
  };



  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          {/* CheckboxAjout */}
          <CheckboxAjout
            titre="Connaissez-vous votre kilométrage annuel ?"
            values={typeKilo}
            setValues={(values) => setTypeKilo(values)}
            valeurInput={valeurInput}
            setValeurInput={(valeurInput) => setValeurInput(valeurInput)}
            transport={transport}
            setTransport={(transport) => handleTransportChange(transport)} // Passer la fonction de rappel pour mettre à jour transport
            unit='km'
            setHandleUnite={handleUniteChange}
    
          />
        </div>
      </div>

      {typeKilo === 'oui' ? null : (
        <div>
          <div className="row">
            <div className="col-12">
              {voyages.map((voyage, index) => (
                <div className="duplique" key={index}>
                  <div className="row col-12">
                    <div className="SousTitre">
                      <DeleteOutlinedIcon
                        sx={{ color: '#024C4A' }}
                        className="Icon"
                        onClick={() => handleDeleteVoyage(index)}
                      />
                      <div className="h61">Voyage {index + 1}</div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-lg-4">
                      {/* ListDeroule pour le voyage en */}
                      <ListDeroule
                        titre="Transport"
                        text="Tram"
                        value={voyage.typeTransport}
                        onChange={(value) =>
                          handleChangeVoyage(index, 'typeTransport', value)
                        }
                        mots={['Metro/RER', 'Bus','Train','Autocar']}
                      />
                    </div>
                    <div className="col-12 col-lg-4">
                      {/* ListDeroule pour le type de voyage */}
                      <ListDeroule
                        titre="Type de voyage"
                        text="Aller - retour"
                        storageKey={`uniqueKey-${index}`}
                        value={voyage.typeVoyage === 279640001 ? 'Aller simple':'Aller - Retour'}
                        onChange={(value) => handleChangeVoyage(index, 'typeVoyage', value==='Aller simple'? 279640001:279640000)}
                        mots={['Aller simple']}
                      />
                    </div>
                  </div>

                  <div className="row" style={{ marginTop: '3vh' }}>
                    <div className="col-12 col-lg-4">
                      {/* InputField pour le départ */}
                      <InputField
                        titre="Départ"
                        value={voyage.depart.name}
                        onChange={(value) =>
                          handleChangeVoyage(index, 'depart', value)
                        }
                        isLocation={true}
                      />
                    </div>
                    <div className="col-12 col-lg-4">
                      {/* InputField pour l'arrivée */}
                      <InputField
                        titre="Arrivée"
                        value={voyage.arrive.name}
                        onChange={(value) =>
                          handleChangeVoyage(index, 'arrive', value)
                        }
                        isLocation={true}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      {/* Ajout pour la fréquence */}
                      <Ajout
                        values={voyage.ajout}
                        setValues={(values) =>
                          handleChangeAjout(index, values)
                        }
                        
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              {/* Bouton pour ajouter un voyage */}
              <button
                type="button"
                className="custom-button"
                onClick={addVoyage}
              >
                Ajouter un voyage
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-12">
        <Bouton titre="SUIVANT" onClick={handleButtonClick} />
          {/* Bouton de soumission du formulaire
          {voyages.map((voyage, index) => (
              <div className="duplique" key={index}>
              <AppelAPITC voyage={voyage} kmConnu={typeKilo} km={valeurInput} transport={transport} unit={selectedUnite} setLancer={setLancer} Lancer={lancer} setApiResponse={updateApiResponse}/> 
                </div>
              ))}  */}
             
            {makeApiCall && (
              <div>
                {voyages.map((voyage, index) => (
                  <div className="duplique" key={index}>
                    <AppelAPITC index={index} voyage={voyage} kmConnu={typeKilo} km={valeurInput} transport={transport} unit={selectedUnite} setLancer={setLancer} Lancer={lancer} save={false} setApiResponse={updateApiResponse} setConsoVoyages={updateConsoVoyage} setAppelTC={updateAppelTC} />
                  </div>
                ))}
              </div>
              )}
        </div>
      </div>

      
      {formSubmitted && (
        // <div>
        //   <div className="row">
        //     <div className="col-12">
        //       {/* BilanInter pour afficher les résultats */}
        //       <BilanInter voyages={voyages} numeroEtape={4} />
        //     </div>
        //   </div>
        <div>
          <div className="row">
            <div className="col-12">
            
            </div>
          </div>
          

          <div className="row">
            <div className="col-12">
              {/* Bouton pour passer à la catégorie suivante */}
              <Bouton
                titre="Catégorie suivante"
                onClick={handleButtonClick}
              />
            </div>
          </div>
        </div>
      )}
    </form>
  );
}

export default FormTC;
