import React, { useState } from 'react';
import "./checkbox.css"
import SousTitre from '../../SousTitre';

const CheckboxChoixUniqueAdapt = ({ titre, reponses, onChange, value=null }) => {
  const [choix, setChoix] = useState(value);

  const handleCheckboxChange = (event) => {
    setChoix(event.target.value);
    onChange(event.target.value);
  };

  return (
    <div className="checkbox">
      <SousTitre SousTitre={titre} place="start" />

      {reponses.map((reponse, index) => (
        <label key={index}>
          <input
            type="checkbox"
            name="choix"
            value={reponse}
            checked={choix === reponse}
            onChange={handleCheckboxChange}
          />
          <span className="checkmark"></span>
          {reponse}
        </label>
      ))}
    </div>
  );
}

export default CheckboxChoixUniqueAdapt;
