import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useAuth } from '../Authentification/Auth';
import { IdBCContext } from '../IdBCContext';
import API_BASE_URL from './config';

const AppelAPIAvion = ({index, voyage, save, lancer, setLancer, setApiResponse, setConsoVoyage, setAppelAvion, setIdBC, filledData, usedUrl, lancerSave, setLancerSave}) => {
  const { token, fetchToken } = useAuth();
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { idBC } = useContext(IdBCContext);


  useEffect(() => {
    // Vérifier si le token est disponible avant de faire la requête
    if (token && lancer) { 
       fetchData();
       setLancer(false)
     }
  }, [token, lancer]); // Se ré-exécute lorsque `token` change

  useEffect(() => {
    // Vérifier si le token est disponible avant de faire la requête
    if(token && lancerSave){
       fetchDataToSave(filledData, usedUrl);
       setLancerSave(false)
     }
  }, [token, lancerSave]); // Se ré-exécute lorsque `token` change


  const fetchDataToSave = async (filledData, usedUrl) => {
    setLoading(true);
    filledData.save = true
    
    const options = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    
    try {
      const result = await axios.post(usedUrl, filledData, options);

    } catch (error) {
      console.error("Erreur lors de l'envoi de la requête :", error);
      setResponse('Erreur lors de la récupération des données.');

    } finally {
      setLoading(false);
      setLancerSave(false)    
    }
  }

  const fetchData = async () => {
    setLoading(true);
    
    const apiUrl = `${API_BASE_URL}/aps_Pavion_Detail`;
    const data = idBC ? 
    {
      IdBC: idBC,
      typeVoyage: voyage.typeVoyage,
      depart: voyage.depart.name,
      latitudeDepart: voyage.depart.latitude,
      longitudeDepart: voyage.depart.longitude,
      arrivee: voyage.arrive.name,
      latitudeArrivee: voyage.arrive.latitude,
      longitudeArrivee: voyage.arrive.longitude,
      frequence: voyage.ajout.frequence,
      unit: voyage.ajout.unit,
      IdPage: "Page voyage avion",
      save: save,
    } : {
      typeVoyage: voyage.typeVoyage,
      depart: voyage.depart.name,
      latitudeDepart: voyage.depart.latitude,
      longitudeDepart: voyage.depart.longitude,
      arrivee: voyage.arrive.name,
      latitudeArrivee: voyage.arrive.latitude,
      longitudeArrivee: voyage.arrive.longitude,
      frequence: voyage.ajout.frequence,
      unit: voyage.ajout.unit,
      IdPage: "Page voyage avion",
      save: save
    };

    const options = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };

    try {
      const result = await axios.post(apiUrl, data, options);
      try {
        // Stocker à jour l'appel à l'API pour plus tard
        setAppelAvion(index, data, apiUrl)
      } catch (error) {
        console.log(error)
      }
      

      // Mettre à jour la réponse de l'API dans le composant parent
      setApiResponse(result.data);
      setConsoVoyage(index, result.data.consoVoyage);
      if (!idBC && result.data.IdBC_New) {
        setIdBC(result.data.IdBC_New)
      }
      setLancer(false);
      console.log(result.data.IdBC_New)
       
      //setResponse(result.data);
    } catch (error) {
      console.error("Erreur lors de l'envoi de la requête :", error);
      setResponse('Erreur lors de la récupération des données.');
    } finally {
      setLoading(false);
    }
  };

  
  return (null);
};

export default AppelAPIAvion;
