import React, { useEffect, useState } from 'react';
import ListDeroule from '../ListDeroule';
import InputField from '../Input';
import Bouton from '../Bouton';
import SousTitre from '../../SousTitre';
import Ajout from '../Ajout';
import BilanInter from '../../BilanInter';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Titre from '../../Titre';
import "./Connection.css"
import InputMotPasse from '../Input/indexMP';
import CheckboxLabels from '../CheckBoxMulti';
import { validate } from 'uuid';
import { getMaxListeners, ppid } from 'process';
import { useNavigate } from 'react-router-dom';
// import { useWordPressAuth } from '../../Authentification/WordPressAuth';
import ApiConnection from '../../Api/ApiConnection';
import AppelAPILinkContactBilanCarbone from '../../Api/AppelAPILinkContactBilanCarbone';
import { useAuth } from '../../UserAuthContext'; // Import du contexte

function FormConnection() {
  // const token = useWordPressAuth();
  const { login } = useAuth();
  const [email, setEmail] = useState('');
  const [motDePasse, setMotDePasse] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [Alerte, setAlerte] = useState(true);
  const [errorFormatEmail, setErrorFormatEmail] = useState(true);
  const [compteInexistant, setCompteInexistant] = useState(false);
  const [lancer, setLancer] = useState(false);
  const [lancerLink, setLancerLink] = useState(false);
  const [hasLinked, setHasLinked] = useState(false);
  const [apiResponse, setResponse] = useState(null);
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return re.test(email);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    sessionStorage.setItem('wpusername', email);
    sessionStorage.setItem('wpmail', email);

    // Vérification de la validité de l'email
    if (validateEmail(email)) {
      setErrorFormatEmail(false);
    } else {
      setErrorFormatEmail(true);
    }

    // Vérification des champs remplis
    if (email && motDePasse ) {
      setAlerte(false);
      setLancer(true)
    } else {
      setAlerte(true);
    }
  };

  const handleFakeSubmit = (event) => {
    event.preventDefault();
    sessionStorage.setItem('wpusername', "mm@pp.fr");
    sessionStorage.setItem('wpmail', "mm@pp.fr");
    login('mm@pp.fr')
    
    if (!hasLinked) { 
      setLancerLink(true)
      setHasLinked(true) 
    }
  };

  const handleFakeNavigation = () => {
    navigate('/bilan');
  };

  const handleCreateAccount = (event) => {
    event.preventDefault();
    navigate('/CreeCompte-3');
  }

  const handleLink = () => {
    if (apiResponse && !hasLinked) { 
      if (apiResponse.status === 200) {
        setLancerLink(true)
        setHasLinked(true) 
      }
    }
  }

  const checkResponse = () => {
    if (apiResponse) {
      if (apiResponse.status === 200) {
        navigate('/bilan');
      }
    }
  }

  useEffect(() => {
    if (formSubmitted && apiResponse) {
      if (apiResponse.code === "ERR_NETWORK") {
        setCompteInexistant(true);
      }
    }
  }, [apiResponse, formSubmitted])

  useEffect(() => {
    setCompteInexistant(false);
    setErrorFormatEmail(false);
    setResponse(null);
    setFormSubmitted(false);
  }, [email, motDePasse, email])

  return (
    <form className='Connection'>
      <div className="row col-12 justify-content-center">
        <div className="SousTitre">
          <Titre
            Titre="Votre profil"
            place="center"
            color="#024C4A"
          />
        </div>
      </div>

      <div className="row col-12 justify-content-center">
        <div className="col-lg-4 col-md-6 col-12">
          <InputField
            titre="Email"
            value={email}
            onChange={setEmail}
            text="Enter your email"
            isLocation={false}
          />
        </div>
      </div>

      <div className="row col-12 justify-content-center">
        <div className="col-lg-4 col-md-6 col-12">
          <InputMotPasse
            titre="Mot de passe"
            value={motDePasse}
            onChange={setMotDePasse}
            text="Mot de passe"
          />
        </div>
      </div>

      {/* {formSubmitted && (
        <div className="row">
          <div className="col-12">
            <p>email: {email}</p>
            <p>Mot de passe: {motDePasse}</p>
          </div>
        </div>
      )} */}

      {formSubmitted && Alerte && (
        <div className="row justify-content-center">
          <div className="col-8">
            <div
              className="alert alert-danger"
              role="alert"
              style={{
                display:
                  email &&
                  motDePasse 
                    ? 'none'
                    : 'block'
              }}
            >
              Veuillez remplir tous les champs !
            </div>
          </div>
        </div>
      )}

      {formSubmitted && errorFormatEmail && (
        <div className="row justify-content-center">
          <div className="col-8">
            <div
              className="alert alert-danger"
              role="alert"
              style={{
                display: 'block'
              }}>
              Mauvais format d'email
            </div>
          </div>
        </div>
      )}

      {formSubmitted && compteInexistant && (
        <div className="row justify-content-center">
          <div className="col-8">
            <div
              className="alert alert-danger"
              role="alert"
            >
              Mauvaise adresse email ou mot de passe !
            </div>
          </div>
        </div>
      )}

      <div className='col-12'>
        <div className="button-container">
          <div className="button-wrapper">
            <Bouton
              type="submit"
              titre="Connexion"
              onClick={handleSubmit}
            />
          </div>
          {/* <div className="button-wrapper">
            <Bouton
              type="submit"
              titre="Simuler une connexion"
              onClick={handleFakeSubmit}
            />
          </div> */}
          <div className="button-wrapper">
            <Bouton
              type="submit"
              titre="Créer mon compte"
              onClick={handleCreateAccount}
            />
          </div>
        </div>
        
        {formSubmitted && !errorFormatEmail && (
          <div>
            <ApiConnection mail={email} pwd={motDePasse} Lancer={lancer} setLancer={setLancer} setResponse={setResponse}/>
          </div>
        )}
        {lancerLink && (
          <AppelAPILinkContactBilanCarbone mail={email} lancerLink={lancerLink} setLancerLink={setLancerLink} />
        )}
        {hasLinked && handleFakeNavigation()}
        {formSubmitted && checkResponse()}
        {formSubmitted &&  handleLink()}
      </div>
    </form>
  );
}

export default FormConnection;
