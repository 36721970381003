import React, { createContext, useState, useContext, useEffect } from 'react';

// Créer le contexte
const appelDictContext = createContext();

export const AppelDictProvider = ({ children }) => {
    const [appelDict, setAppelDict] = useState(JSON.parse(sessionStorage.getItem("appelDict")) || {});

    useEffect(()=>{
        sessionStorage.setItem("appelDict", JSON.stringify(appelDict))
    })
    return (
        <appelDictContext.Provider value={{ appelDict, setAppelDict }}>
            {children}
        </appelDictContext.Provider>
    );
};

// Custom hook pour utiliser le contexte
export const useAppelDict = () => useContext(appelDictContext);
